import React, { MouseEventHandler } from "react";
import { ImageType } from "../../models/ImageType";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export type ImageProps = {
  image: ImageType;
  onClick?: MouseEventHandler<HTMLImageElement> | undefined;
};

export const Image = (props: ImageProps) => {
  if (props.image.isBase64) {
    return (
      <img
        src={props.image.base64}
        onClick={props.onClick}
        alt={props.image.accessibleDescription}
      />
    );
  } else if (props.image.url) {
    return (
      <img
        src={BASE_URL + props.image.url}
        onClick={props.onClick}
        alt={props.image.accessibleDescription}
      />
    );
  }
  return null;
};
