import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { SpeeltuinchefContext } from "../store/Store";
import { useContext, useEffect, useState } from "react";
import { checkAndLoadState, hasValidSession } from "../helpers/SessionHelper";
import LoginPage from "../page/LoginPage";
import { Loading } from "../components/molecules/Loading";
import { PlaygroundsPage } from "../page/PlaygroundsPage";
import { PlaygroundsEditGeneralPage } from "../page/PlaygroundsEditGeneralPage";
import { PlaygroundsNewPage } from "../page/PlaygroundsNewPage";
import { PlaygroundsEditPhasePage } from "../page/PlaygroundEditPhasePage";
import { PlaygroundEditQuestionnairePage } from "../page/PlaygroundEditQuestionnairePage";
import { PlaygroundNewQuestionnairePage } from "../page/PlaygroundNewQuestionnairePage";
import { PlaygroundEditAddressesPage } from "../page/PlaygroundEditAddressesPage";
import { PlaygroundNewAddressPage } from "../page/PlaygroundNewAddressPage";
import { PlaygroundEditMoodboards } from "../page/PlaygroundEditMoodboardsPage";
import { PlaygroundEditActivitiesPage } from "../page/PlaygroundEditActivitiesPage";
import { PlaygroundEditIngredientsPage } from "../page/PlaygroundEditIngredientsPage";
import { PlaygroundEditConceptsPage } from "../page/PlaygroundEditConceptsPage";
import { PlaygroundEditConceptPage } from "../page/PlaygroundEditConceptPage";
import { PlaygroundNewConceptPage } from "../page/PlaygroundNewConceptPage";
import { PlaygroundResultsPage } from "../page/PlaygroundResultsPage";
import { MoodboardsPage } from "../page/MoodboardsPage";
import { ActivitiesPage } from "../page/ActivitiesPage";
import { IngredientsPage } from "../page/IngredientsPage";
import { UsersPage } from "../page/UsersPage";
import { PlaygroundNewAddressCsvPage } from "../page/PlaygroundNewAddressCsvPage";
import { PlaygroundMailingPage } from "../page/PlaygroundMailingPage";

export default function SpeeltuinchefRoute() {
  const context = useContext(SpeeltuinchefContext);
  const { state } = context;
  const [isLoadingState, setIsLoadingState] = useState(false);

  useEffect(() => {
    if (!isLoadingState && hasValidSession(context)) {
      setIsLoadingState(true);
      checkAndLoadState(context);
    }
  });

  const routes = hasValidSession(context)
    ? state.user === undefined || state.user.email === null
      ? LoadingRoute()
      : LoggedInRoute()
    : OpenRoute();

  return <BrowserRouter>{routes}</BrowserRouter>;
}

export const OpenRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
    </Routes>
  );
};

export const LoggedInRoute = () => {
  return (
    <Routes>
      <Route path="/playgrounds/new" element={<PlaygroundsNewPage />} />
      <Route
        path="/playgrounds/:playgroundId/general"
        element={<PlaygroundsEditGeneralPage />}
      />
      <Route
        path="/playgrounds/:playgroundId/phases"
        element={<PlaygroundsEditPhasePage />}
      />
      <Route
        path="/playgrounds/:playgroundId/questionnaire/new"
        element={<PlaygroundNewQuestionnairePage />}
      />
      <Route
        path="/playgrounds/:playgroundId/questionnaire"
        element={<PlaygroundEditQuestionnairePage />}
      />
      <Route
        path="/playgrounds/:playgroundId/addresses/new"
        element={<PlaygroundNewAddressPage />}
      />
      <Route
        path="/playgrounds/:playgroundId/addresses/csv"
        element={<PlaygroundNewAddressCsvPage />}
      />
      <Route
        path="/playgrounds/:playgroundId/addresses"
        element={<PlaygroundEditAddressesPage />}
      />
      <Route
        path="/playgrounds/:playgroundId/moodboards"
        element={<PlaygroundEditMoodboards />}
      />
      <Route
        path="/playgrounds/:playgroundId/activities"
        element={<PlaygroundEditActivitiesPage />}
      />
      <Route
        path="/playgrounds/:playgroundId/mailing"
        element={<PlaygroundMailingPage />}
      />
      <Route
        path="/playgrounds/:playgroundId/ingredients"
        element={<PlaygroundEditIngredientsPage />}
      />
      <Route
        path="/playgrounds/:playgroundId/concepts/edit/:conceptId"
        element={<PlaygroundEditConceptPage />}
      />
      <Route
        path="/playgrounds/:playgroundId/concepts/new"
        element={<PlaygroundNewConceptPage />}
      />
      <Route
        path="/playgrounds/:playgroundId/concepts"
        element={<PlaygroundEditConceptsPage />}
      />
      <Route
        path="/playgrounds/:playgroundId/results"
        element={<PlaygroundResultsPage />}
      />
      <Route path="/playgrounds" element={<PlaygroundsPage />} />
      <Route path="/moodboards" element={<MoodboardsPage />} />
      <Route path="/activities" element={<ActivitiesPage />} />
      <Route path="/ingredients" element={<IngredientsPage />} />
      <Route path="/users" element={<UsersPage />} />
      <Route path="/" element={<PlaygroundsPage />} />
    </Routes>
  );
};

export const LoadingRoute = () => {
  return (
    <Routes>
      <Route element={<Loading />} />
    </Routes>
  );
};
