import React from "react";
import { PlaygroundClientType } from "../../models/PlaygroundClientType";
import { ShoppinglistType } from "../../models/ShoppinglistType";
import { QuestionType } from "../../models/QuestionType";

export type ResultQuestionsProps = {
  playground: PlaygroundClientType;
  shoppinglists: Array<ShoppinglistType>;
  onlyFinishedBoxes: boolean;
};
export const ResultQuestions = (props: ResultQuestionsProps) => {
  const getAnswers = () => {
    return props.playground.questions.map((question) => {
      const outputHtml = [];
      outputHtml.push(<h3>{question.question}</h3>);
      if (question.type === "text") {
        outputHtml.push(
          <div className={"results__answer"}>{getTextAnswers(question)}</div>
        );
      }
      if (question.type === "select") {
        outputHtml.push(
          <div className={"results__answer"}>{getSelectAnswers(question)}</div>
        );
      }

      if (question.type === "checkbox") {
        outputHtml.push(
          <div className={"results__answer"}>{getSelectAnswers(question)}</div>
        );
      }

      return outputHtml;
    });
  };

  const countOccurrences = (arr: Array<string>, val: string) =>
    arr.reduce((a, v) => (v === val ? a + 1 : a), 0);

  const getSelectAnswers = (question: QuestionType) => {
    const givenAnswers: Array<string> = [];
    props.shoppinglists.map((shoppingList) => {
      if (
        !props.onlyFinishedBoxes ||
        (props.onlyFinishedBoxes && shoppingList.isFinalized)
      ) {
        shoppingList.answers.map((answer) => {
          if (answer.questionId === question.id) {
            givenAnswers.push(answer.answer);
          }
        });
      }
    });

    return question.options.map((option, index) => {
      return (
        <span key={index}>
          {countOccurrences(givenAnswers, option) + "x " + option}
        </span>
      );
    });
  };

  const getTextAnswers = (question: QuestionType) => {
    return props.shoppinglists.map((shoppingList) => {
      if (
        !props.onlyFinishedBoxes ||
        (props.onlyFinishedBoxes && shoppingList.isFinalized)
      ) {
        return shoppingList.answers.map((answer, index) => {
          if (answer.questionId === question.id) {
            return <span key={index}>{answer.answer}</span>;
          }
        });
      }
    });
  };

  return <div>{getAnswers()}</div>;
};
