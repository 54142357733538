import React from "react";
import { Link } from "react-router-dom";
import { ConceptType } from "../../models/ConceptType";
import { PlaygroundClientType } from "../../models/PlaygroundClientType";
import { PlaygroundType } from "../../models/PlaygroundType";

export type ConceptListProps = {
  concepts: Array<ConceptType>;
  playground: PlaygroundType | PlaygroundClientType;
};

export const ConceptList = (props: ConceptListProps) => {
  const getConcepts = (): React.ReactNode => {
    return props.concepts.map((concept) => {
      return (
        <Link
          key={"concept-" + concept.id}
          to={
            "/playgrounds/" +
            props.playground.id +
            "/concepts/edit/" +
            concept.id
          }
          className={"playgroundslist__item"}
        >
          <div className={"playgroundslist__value"}>{concept.title}</div>

          <div className={"playgroundslist__value"}>
            <img src={"/assets/images/menu/pencil.svg"} alt={"Potlood"} />
          </div>
        </Link>
      );
    });
  };
  return <div className={"playgroundlist__playgrounds"}>{getConcepts()}</div>;
};
