import { revokeSession } from "../../helpers/SessionHelper";
import { PlaygroundType } from "../../models/PlaygroundType";
import { PlaygroundNewRequest } from "../../models/request/PlaygroundNewRequest";
import { PlaygroundClientType } from "../../models/PlaygroundClientType";
import { AddressType } from "../../models/AddressType";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getPlaygrounds = (
  sessionToken: string
): Promise<Array<PlaygroundType>> => {
  return fetch(BASE_URL + "/playgrounds/list", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "X-ACCESS-TOKEN": sessionToken,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        revokeSession();
        return;
      }
      return response.json();
    })
    .then((response) => response);
};

export const newPlayground = (
  newPlayground: PlaygroundNewRequest,
  sessionToken: string
): Promise<PlaygroundType> => {
  return fetch(BASE_URL + "/playgrounds/new", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "X-ACCESS-TOKEN": sessionToken,
    },
    body: JSON.stringify(newPlayground),
  })
    .then((response) => response.json())
    .then((response) => response)
    .catch((e) => {
      console.error(e);
    });
};

export const updatePlayground = (
  playground: PlaygroundType | PlaygroundClientType,
  sessionToken: string
): Promise<PlaygroundType> => {
  return fetch(BASE_URL + "/playgrounds/" + playground.id + "/update", {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "X-ACCESS-TOKEN": sessionToken,
    },
    body: JSON.stringify(playground),
  })
    .then((response) => response.json())
    .then((response) => response)
    .catch((e) => {
      console.error(e);
    });
};

export const getPlaygroundDetails = (
  sessionToken: string,
  playground: PlaygroundType
): Promise<PlaygroundClientType> => {
  return fetch(BASE_URL + "/playgrounds/" + playground.id + "/list", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "X-ACCESS-TOKEN": sessionToken,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        revokeSession();
        return;
      }
      return response.json();
    })
    .then((response) => response);
};

export const addAddressToPlayground = (
  playground: PlaygroundType | PlaygroundClientType,
  address: AddressType,
  sessionToken: string
): Promise<[]> => {
  return fetch(BASE_URL + "/playgrounds/" + playground.id + "/addAddress", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "X-ACCESS-TOKEN": sessionToken,
    },
    body: JSON.stringify(address),
  })
    .then((response) => response.json())
    .then((response) => response)
    .catch((e) => {
      console.error(e);
    });
};

export const addBulkAddressToPlayground = (
  playground: PlaygroundType | PlaygroundClientType,
  addresses: Array<AddressType>,
  sessionToken: string
): Promise<[]> => {
  return fetch(
    BASE_URL + "/playgrounds/" + playground.id + "/addBulkAddresses",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "X-ACCESS-TOKEN": sessionToken,
      },
      body: JSON.stringify({ addresses: addresses }),
    }
  )
    .then((response) => response.json())
    .then((response) => response)
    .catch((e) => {
      console.error(e);
    });
};

export const removeAddressToPlayground = (
  playground: PlaygroundType | PlaygroundClientType,
  addressId: number,
  sessionToken: string
): Promise<[]> => {
  return fetch(
    BASE_URL + "/playgrounds/" + playground.id + "/removeAddress/" + addressId,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "X-ACCESS-TOKEN": sessionToken,
      },
    }
  )
    .then((response) => response.json())
    .then((response) => response)
    .catch((e) => {
      console.error(e);
    });
};

export const removePlayground = (
  playground: PlaygroundType | PlaygroundClientType,
  sessionToken: string
): Promise<[]> => {
  return fetch(BASE_URL + "/playgrounds/" + playground.id + "/delete", {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "X-ACCESS-TOKEN": sessionToken,
    },
  })
    .then((response) => response.json())
    .then((response) => response)
    .catch((e) => {
      console.error(e);
    });
};
