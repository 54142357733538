import React from "react";
import { NavBar } from "../components/organisms/NavBar";
import { PlaygroundsList } from "../components/organisms/PlaygroundsList";

export const PlaygroundsPage = () => {
  return (
    <div>
      <NavBar />
      <PlaygroundsList />
    </div>
  );
};
