import { revokeSession } from "../../helpers/SessionHelper";
import { UserResponse } from "../../models/response/UserResponse";
import { LoginResponse } from "../../models/response/LoginResponse";
import { LoginRequest } from "../../models/request/LoginRequest";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getUser = (sessionToken: string): Promise<UserResponse> => {
  return fetch(BASE_URL + "/user/me", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "X-ACCESS-TOKEN": sessionToken,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        revokeSession();
        return;
      }
      return response.json();
    })
    .then((userResponse) => userResponse);
};

export const postLogin = (login: LoginRequest): Promise<LoginResponse> => {
  return fetch(BASE_URL + "/user/login", {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: JSON.stringify(login),
  })
    .then((response) => response.json())
    .then((loginResponse) => loginResponse)
    .catch((e) => {
      console.error(e);
    });
};

export const addUser = (
  sessionToken: string,
  user: LoginRequest
): Promise<[]> => {
  return fetch(BASE_URL + "/user/roleCreate", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "X-ACCESS-TOKEN": sessionToken,
    },
    body: JSON.stringify(user),
  })
    .then((response) => response.json())
    .then((loginResponse) => loginResponse)
    .catch((e) => {
      console.error(e);
    });
};
