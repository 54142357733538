import React, { useContext } from "react";
import { NavBar } from "../components/organisms/NavBar";
import { Image } from "../components/atoms/Image";
import { SpeeltuinchefContext } from "../store/Store";

export const ActivitiesPage = () => {
  const { state } = useContext(SpeeltuinchefContext);

  const getActivities = (): React.ReactNode => {
    if (!state.activities) {
      return <div />;
    }
    return state.activities.map((activity) => {
      return (
        <div key={activity.id} className={"moodboardList__moodboard"}>
          {activity.title}
          <div className={"moodboardList__image"}>
            <Image image={activity.image} />
          </div>
        </div>
      );
    });
  };
  return (
    <div>
      <NavBar />
      <div className={"block"}>
        <h1>Activiteiten</h1> {getActivities()}
      </div>
    </div>
  );
};
