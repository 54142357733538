import React, { ChangeEvent, useState } from "react";
import { ImageType } from "../../models/ImageType";

export type ImageUploadProps = {
  maxUploadKB: number;
  addImage: (image: ImageType) => void;
};

export const ImageUpload = (props: ImageUploadProps) => {
  const [warningText, setWarningText] = useState<string | null>(null);
  const [base64, setBase64] = useState<string | null>(null);
  const [accessibilityText, setAccessibilityText] = useState<string>("");
  const handleChangeImage = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      setWarningText("Geen afbeelding geselecteerd");
      return;
    }

    const file = files[0];

    if (!checkFileType(file.type)) {
      setWarningText("Selecteer een afbeelding in JPG, PNG of SVG formaat.");
      return;
    }

    if (file.size / 1000 > props.maxUploadKB) {
      setWarningText(
        "Afbeelding mag maximaal " +
          Math.round((props.maxUploadKB / 1000) * 100) / 100 +
          " MB groot zijn"
      );
      return;
    }

    const result = await convertBase64(file);
    if (typeof result === "string") {
      setWarningText(null);
      setBase64(result);
    } else {
      setWarningText(
        "Er is iets mis gegaan met de afbeelding, probeer het nog eens."
      );
      return;
    }
  };

  const convertBase64 = (file: File): Promise<string | null | ArrayBuffer> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const checkFileType = (fileType: string): boolean => {
    return fileType.includes("image");
  };

  const addImage = () => {
    setBase64(null);
    setAccessibilityText("");
    setWarningText(null);
    props.addImage({
      id: 0,
      url: null,
      accessibleDescription: accessibilityText,
      isBase64: true,
      base64: base64 ? base64 : "",
    });
  };

  const warning = warningText ? (
    <div className={"message message--warning"} role={"alert"}>
      {warningText}
    </div>
  ) : null;

  return (
    <div className={"imageUpload"}>
      {base64 ? (
        <div>
          <textarea
            placeholder={
              "Omschrijving wat er zichtbaar is op de afbeelding voor schermlezers"
            }
            value={accessibilityText}
            onChange={(e) => setAccessibilityText(e.target.value)}
          />
          <button className={"button"} onClick={addImage}>
            Voeg toe
          </button>
        </div>
      ) : (
        <label>
          <img src={"/assets/images/placeholder-image.png"} />
          <span>Afbeelding toevoegen</span>
          <input type={"file"} onChange={handleChangeImage} />
        </label>
      )}

      {warning}
    </div>
  );
};
