import React, { useContext, useState } from "react";
import { PlaygroundClientType } from "../../models/PlaygroundClientType";
import { SpeeltuinchefContext } from "../../store/Store";
import { CheckBox } from "../atoms/Checkbox";
import { Image } from "../atoms/Image";
import { updatePlayground } from "../../api/playgrounds/PlaygroundCalls";
import { setIngredients } from "../../api/ingredients/IngredientCalls";

export type IngredientsListProps = {
  playground: PlaygroundClientType;
};

export const IngredientsList = (props: IngredientsListProps) => {
  const context = useContext(SpeeltuinchefContext);
  const { state } = context;
  const [isLoading, setIsLoading] = useState(false);
  const [successText, setSuccessText] = useState<null | string>(null);

  const getSelectedIngredientIds = (): Array<number> => {
    const ingredientIds: Array<number> = [];

    props.playground.ingredients.forEach((ingredient) => {
      ingredientIds.push(ingredient.id);
    });

    return ingredientIds;
  };

  const [ingredientIds, setIngredientIds] = useState(
    getSelectedIngredientIds()
  );
  const [maxIngredientPoints, setMaxIngredientPoints] = useState(
    props.playground.maxIngredientPoints
  );

  const saveIngredients = async () => {
    setIsLoading(true);

    if (!state.session.token) {
      setIsLoading(false);
      setSuccessText(null);
      return;
    }

    try {
      await setIngredients(state.session.token, props.playground, {
        ingredients: ingredientIds,
      });

      const playground = props.playground;
      playground.maxIngredientPoints = maxIngredientPoints;
      await updatePlayground(playground, state.session.token);
      setSuccessText("Ingrediënten opgeslagen");
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      setSuccessText(null);
    }
  };

  const getCoins = (numberOfCoints: number): React.ReactNode => {
    const coins = [];
    for (let coinCount = 0; coinCount < numberOfCoints; coinCount++) {
      coins.push(
        <img
          src={"/assets/images/coin.svg"}
          alt={"Muntje"}
          key={"coin" + coinCount}
        />
      );
    }

    return <div>{coins}</div>;
  };

  const toggleAllIngredients = () => {
    if (
      state.ingredients &&
      ingredientIds.length === state.ingredients.length
    ) {
      setIngredientIds([]);
    } else if (state.ingredients) {
      setIngredientIds(state.ingredients.map((ingredient) => ingredient.id));
    }
  };

  const getIngredients = (): React.ReactNode => {
    if (!state.ingredients) {
      return <div />;
    }
    return state.ingredients.map((ingredient) => {
      return (
        <div key={ingredient.id} className={"moodboardList__moodboard"}>
          <CheckBox
            ariaText={"Selecteer " + ingredient.title}
            checked={ingredientIds.includes(ingredient.id)}
            text={ingredient.title}
            onChangeValueFunction={(value: boolean) => {
              if (value) {
                setIngredientIds([...ingredientIds, ingredient.id]);
              } else {
                setIngredientIds(
                  ingredientIds.filter((ingredientId) => {
                    return ingredientId !== ingredient.id;
                  })
                );
              }
            }}
          />
          <div className={"moodboardList__image"}>
            <Image image={ingredient.image} />
          </div>
          <div className={"moodboardList__coins"}>
            {getCoins(ingredient.points)}
          </div>
        </div>
      );
    });
  };

  const success = successText ? (
    <div className={"message message--success"} role={"alert"}>
      {successText}
    </div>
  ) : null;
  return (
    <div className={"moodboardList"}>
      {success}
      <div>
        Maximaal aantal punten:{" "}
        <input
          type={"number"}
          value={maxIngredientPoints}
          className={"smallInput"}
          onChange={(e) => setMaxIngredientPoints(parseInt(e.target.value))}
        />
      </div>
      <div>
        <a
          onClick={() => {
            toggleAllIngredients();
          }}
          className={"action"}
        >
          (de)selecteer alles
        </a>
      </div>
      {getIngredients()}
      <p>
        <button className={"button"} onClick={saveIngredients}>
          {isLoading ? <img src={"/assets/images/loader.svg"} /> : "Opslaan"}
        </button>
      </p>
    </div>
  );
};
