import React, { useContext, useState } from "react";
import { PlaygroundClientType } from "../../models/PlaygroundClientType";
import { SpeeltuinchefContext } from "../../store/Store";
import { CheckBox } from "../atoms/Checkbox";
import { Image } from "../atoms/Image";
import { setMoodboards } from "../../api/moodboards/MoodboardCalls";
import { updatePlayground } from "../../api/playgrounds/PlaygroundCalls";

export type MoodboardListProps = {
  playground: PlaygroundClientType;
};

export const MoodboardList = (props: MoodboardListProps) => {
  const context = useContext(SpeeltuinchefContext);
  const { state } = context;
  const [isLoading, setIsLoading] = useState(false);
  const [successText, setSuccessText] = useState<null | string>(null);

  const getSelectedMoodboardIds = (): Array<number> => {
    const moodboardIds: Array<number> = [];

    props.playground.moodboards.forEach((moodboard) => {
      moodboardIds.push(moodboard.id);
    });

    return moodboardIds;
  };

  const [moodboardIds, setMoodboardIds] = useState(getSelectedMoodboardIds());
  const [maxSelectableMoodboards, setMaxSelectableMoodboards] = useState(
    props.playground.maxSelectableMoodboards
  );

  const saveMoodboards = async () => {
    setIsLoading(true);

    if (!state.session.token) {
      setIsLoading(false);
      setSuccessText(null);
      return;
    }

    try {
      await setMoodboards(state.session.token, props.playground, {
        moodboards: moodboardIds,
      });

      const playground = props.playground;
      playground.maxSelectableMoodboards = maxSelectableMoodboards;
      await updatePlayground(playground, state.session.token);
      setSuccessText("Stijlen opgeslagen");
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      setSuccessText(null);
    }
  };

  const toggleAllMoodboards = () => {
    if (state.moodboards && moodboardIds.length === state.moodboards.length) {
      setMoodboardIds([]);
    } else if (state.moodboards) {
      setMoodboardIds(state.moodboards.map((moodboard) => moodboard.id));
    }
  };

  const getMoodboards = (): React.ReactNode => {
    if (!state.moodboards) {
      return <div />;
    }
    return state.moodboards.map((moodboard) => {
      return (
        <div key={moodboard.id} className={"moodboardList__moodboard"}>
          <CheckBox
            ariaText={"Selecteer " + moodboard.title}
            checked={moodboardIds.includes(moodboard.id)}
            text={moodboard.title}
            onChangeValueFunction={(value: boolean) => {
              if (value) {
                setMoodboardIds([...moodboardIds, moodboard.id]);
              } else {
                setMoodboardIds(
                  moodboardIds.filter((moodboardId) => {
                    return moodboardId !== moodboard.id;
                  })
                );
              }
            }}
          />
          <div className={"moodboardList__image"}>
            <Image image={moodboard.image} />
          </div>
        </div>
      );
    });
  };

  const success = successText ? (
    <div className={"message message--success"} role={"alert"}>
      {successText}
    </div>
  ) : null;
  return (
    <div className={"moodboardList"}>
      {success}
      <div>
        Maximaal aantal stijlen:{" "}
        <input
          type={"number"}
          value={maxSelectableMoodboards}
          className={"smallInput"}
          onChange={(e) => setMaxSelectableMoodboards(parseInt(e.target.value))}
        />
      </div>
      <div>
        <a
          onClick={() => {
            toggleAllMoodboards();
          }}
          className={"action"}
        >
          (de)selecteer alles
        </a>
      </div>
      {getMoodboards()}
      <p>
        <button className={"button"} onClick={saveMoodboards}>
          {isLoading ? <img src={"/assets/images/loader.svg"} /> : "Opslaan"}
        </button>
      </p>
    </div>
  );
};
