import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SpeeltuinchefContext } from "../store/Store";
import {
  getPlaygroundById,
  getPlaygroundDetailsById,
} from "../helpers/PlaygroundHelper";
import { PlaygroundClientType } from "../models/PlaygroundClientType";

import { NavBar } from "../components/organisms/NavBar";
import { Loading } from "../components/molecules/Loading";
import { IngredientsList } from "../components/organisms/IngredientsList";

export const PlaygroundEditIngredientsPage = () => {
  const { playgroundId } = useParams<{ playgroundId: string }>();
  const context = useContext(SpeeltuinchefContext);
  const playground = getPlaygroundById(parseInt(playgroundId ?? ""), context);

  const [
    apiPlayground,
    setApiPlayground,
  ] = useState<PlaygroundClientType | null>(null);

  const fetchPlayground = async () => {
    const apiPlayground = await getPlaygroundDetailsById(
      parseInt(playgroundId ?? ""),
      context
    );
    setApiPlayground(apiPlayground);
  };

  useEffect(() => {
    if (apiPlayground === null) {
      fetchPlayground();
    }
  });

  return (
    <div>
      <NavBar playground={playground} />
      <div className={"block"}>
        <div className={"block__title"}>
          <h1>Ingrediënten</h1>
        </div>
        <div className={"block__content"}>
          {apiPlayground === null ? (
            <Loading />
          ) : (
            <IngredientsList playground={apiPlayground} />
          )}
        </div>
      </div>
    </div>
  );
};
