import React, { useContext, useState } from "react";
import { PlaygroundClientType } from "../../models/PlaygroundClientType";
import { SpeeltuinchefContext } from "../../store/Store";
import { CheckBox } from "../atoms/Checkbox";
import { Image } from "../atoms/Image";
import { updatePlayground } from "../../api/playgrounds/PlaygroundCalls";
import { setActivities } from "../../api/activities/ActivityCalls";

export type ActivityListProps = {
  playground: PlaygroundClientType;
};

export const ActivityList = (props: ActivityListProps) => {
  const context = useContext(SpeeltuinchefContext);
  const { state } = context;
  const [isLoading, setIsLoading] = useState(false);
  const [successText, setSuccessText] = useState<null | string>(null);

  const getSelectedActivityIds = (): Array<number> => {
    const activityIds: Array<number> = [];

    props.playground.activities.forEach((activity) => {
      activityIds.push(activity.id);
    });

    return activityIds;
  };

  const [activityIds, setActivityIds] = useState(getSelectedActivityIds());
  const [maxSelectableActivities, setMaxSelectableActivities] = useState(
    props.playground.maxSelectableActivities
  );

  const saveActivities = async () => {
    setIsLoading(true);

    if (!state.session.token) {
      setIsLoading(false);
      setSuccessText(null);
      return;
    }

    try {
      await setActivities(state.session.token, props.playground, {
        activities: activityIds,
      });

      const playground = props.playground;
      playground.maxSelectableActivities = maxSelectableActivities;
      await updatePlayground(playground, state.session.token);
      setSuccessText("Activiteiten opgeslagen");
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      setSuccessText(null);
    }
  };

  const getActivities = (): React.ReactNode => {
    if (!state.activities) {
      return <div />;
    }
    return state.activities.map((activity) => {
      return (
        <div key={activity.id} className={"moodboardList__moodboard"}>
          <CheckBox
            ariaText={"Selecteer " + activity.title}
            checked={activityIds.includes(activity.id)}
            text={activity.title}
            onChangeValueFunction={(value: boolean) => {
              if (value) {
                setActivityIds([...activityIds, activity.id]);
              } else {
                setActivityIds(
                  activityIds.filter((activityId) => {
                    return activityId !== activity.id;
                  })
                );
              }
            }}
          />
          <div className={"moodboardList__image"}>
            <Image image={activity.image} />
          </div>
        </div>
      );
    });
  };

  const toggleAllActivities = () => {
    if (state.activities && activityIds.length === state.activities.length) {
      setActivityIds([]);
    } else if (state.activities) {
      setActivityIds(state.activities.map((activity) => activity.id));
    }
  };

  const success = successText ? (
    <div className={"message message--success"} role={"alert"}>
      {successText}
    </div>
  ) : null;
  return (
    <div className={"moodboardList"}>
      {success}
      <div>
        Maximaal aantal activiteiten:{" "}
        <input
          type={"number"}
          value={maxSelectableActivities}
          className={"smallInput"}
          onChange={(e) => setMaxSelectableActivities(parseInt(e.target.value))}
        />
      </div>
      <div>
        <a
          onClick={() => {
            toggleAllActivities();
          }}
          className={"action"}
        >
          (de)selecteer alles
        </a>
      </div>
      {getActivities()}
      <p>
        <button className={"button"} onClick={saveActivities}>
          {isLoading ? <img src={"/assets/images/loader.svg"} /> : "Opslaan"}
        </button>
      </p>
    </div>
  );
};
