import React, { useContext, useEffect, useState } from "react";
import { NavBar } from "../components/organisms/NavBar";
import { useParams } from "react-router-dom";
import { SpeeltuinchefContext } from "../store/Store";
import {
  getPlaygroundById,
  getPlaygroundDetailsById,
} from "../helpers/PlaygroundHelper";
import { PlaygroundClientType } from "../models/PlaygroundClientType";
import { Loading } from "../components/molecules/Loading";
import { SubMenu } from "../components/organisms/SubMenu";
import { ResultAddresses } from "../components/organisms/ResultAddresses";
import { ResultMoodboards } from "../components/organisms/ResultMoodboards";
import { ResultActivities } from "../components/organisms/ResultActivities";
import { CheckBox } from "../components/atoms/Checkbox";
import { ResultIngredients } from "../components/organisms/ResultIngredients";
import { ResultQuestions } from "../components/organisms/ResultQuestions";
import { ResultRemarks } from "../components/organisms/ResultRemarks";
import { ResultConcepts } from "../components/organisms/ResultConcepts";
import { ResultFamily } from "../components/organisms/ResultFamily";

export const PlaygroundResultsPage = () => {
  const { playgroundId } = useParams<{ playgroundId: string }>();
  const context = useContext(SpeeltuinchefContext);
  const { state } = context;
  const playground = getPlaygroundById(parseInt(playgroundId ?? ""), context);

  const [activeItem, setActiveItem] = useState("addresses");
  const [onlyFinishedBoxes, setOnlyFinishedBoxes] = useState(true);

  const [
    apiPlayground,
    setApiPlayground,
  ] = useState<PlaygroundClientType | null>(null);

  const fetchPlayground = async () => {
    const apiPlayground = await getPlaygroundDetailsById(
      parseInt(playgroundId ?? ""),
      context
    );
    setApiPlayground(apiPlayground);
  };

  useEffect(() => {
    if (apiPlayground === null) {
      fetchPlayground();
    }
  });

  const getResultsComponent = (): React.ReactNode => {
    if (!apiPlayground) {
      return <div />;
    }

    if (activeItem === "addresses") {
      return (
        <ResultAddresses
          shoppinglists={apiPlayground.shoppingLists}
          playground={apiPlayground}
          onlyFinishedBoxes={onlyFinishedBoxes}
        />
      );
    } else if (activeItem === "moodboards") {
      return (
        <ResultMoodboards
          shoppinglists={apiPlayground.shoppingLists}
          playground={apiPlayground}
          onlyFinishedBoxes={onlyFinishedBoxes}
        />
      );
    } else if (activeItem === "activities") {
      return (
        <ResultActivities
          shoppinglists={apiPlayground.shoppingLists}
          playground={apiPlayground}
          onlyFinishedBoxes={onlyFinishedBoxes}
        />
      );
    } else if (activeItem === "ingredients") {
      return (
        <ResultIngredients
          shoppinglists={apiPlayground.shoppingLists}
          playground={apiPlayground}
          onlyFinishedBoxes={onlyFinishedBoxes}
        />
      );
    } else if (activeItem === "questionnaire") {
      if (!apiPlayground.hasQuestionnaire) {
        return (
          <div className={"message message--warning"}>
            Vragenlijst niet ingeschakeld voor speeltuin
          </div>
        );
      }
      return (
        <ResultQuestions
          shoppinglists={apiPlayground.shoppingLists}
          playground={apiPlayground}
          onlyFinishedBoxes={onlyFinishedBoxes}
        />
      );
    } else if (activeItem === "remarks") {
      return (
        <ResultRemarks
          shoppinglists={apiPlayground.shoppingLists}
          playground={apiPlayground}
          onlyFinishedBoxes={onlyFinishedBoxes}
        />
      );
    } else if (activeItem === "concepts") {
      return (
        <ResultConcepts
          shoppinglists={apiPlayground.shoppingLists}
          playground={apiPlayground}
          onlyFinishedBoxes={onlyFinishedBoxes}
        />
      );
    } else if (activeItem === "family") {
      return (
        <ResultFamily
          shoppinglists={apiPlayground.shoppingLists}
          playground={apiPlayground}
          onlyFinishedBoxes={onlyFinishedBoxes}
          families={state.families}
        />
      );
    }

    return <div />;
  };

  return (
    <div>
      <NavBar playground={playground} />
      <div className={"block"}>
        <div className={"block__title"}>
          <h1>Resultaten</h1>
        </div>
        <div className={"block__content"}>
          {apiPlayground === null ? (
            <Loading />
          ) : (
            <div>
              <SubMenu active={activeItem} setActive={setActiveItem} />
              <div className={"results__checkbox"}>
                <CheckBox
                  ariaText={"Alleen ingestuurde lijste "}
                  text={"Alleen ingestuurde lijsten"}
                  checked={onlyFinishedBoxes}
                  onChangeSetStateFunction={setOnlyFinishedBoxes}
                />
              </div>
              {getResultsComponent()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
