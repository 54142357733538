import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SpeeltuinchefContext } from "../store/Store";
import { getPlaygroundById } from "../helpers/PlaygroundHelper";
import { NavBar } from "../components/organisms/NavBar";
import Papa from "papaparse";
import { CheckBox } from "../components/atoms/Checkbox";
import {
  addAddressToPlayground,
  addBulkAddressToPlayground,
} from "../api/playgrounds/PlaygroundCalls";

export const PlaygroundNewAddressCsvPage = () => {
  const { playgroundId } = useParams<{ playgroundId: string }>();
  const context = useContext(SpeeltuinchefContext);
  const { state } = context;
  const navigate = useNavigate();
  const playground = getPlaygroundById(parseInt(playgroundId ?? ""), context);

  const [hasFile, setHasFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [csvJson, setCsvJson] = useState<Array<Array<any>>>([]);
  const [useFirstRow, setUseFirstRow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [postalCodeIndex, setPostalCodeIndex] = useState<number | null>(null);
  const [housenumberIndex, setHousenumberIndex] = useState<number | null>(null);
  const [additionIndex, setAdditionIndex] = useState<number | null>(null);
  const [streetIndex, setStreetIndex] = useState<number | null>(null);

  const handleCsvFileUpload = (files: FileList | null) => {
    if (files) {
      const file = files.item(0);
      if (file) {
        if (file.type !== "text/csv") {
          setErrorMessage("Voer een CSV bestand in");
          setHasFile(false);
          return;
        } else {
          csvToJson(file);
          setHasFile(true);
          return;
        }
      }
    }
    setErrorMessage("Selecteer een bestand");
    setHasFile(false);
  };

  const csvToJson = (file: File) => {
    Papa.parse(file, {
      complete: function (results) {
        // @ts-ignore
        setCsvJson(results.data);
      },
    });
  };

  const getFileInput = () => {
    return (
      <div>
        {errorMessage && (
          <div className={"message message--warning"} role={"alert"}>
            {errorMessage}
          </div>
        )}
        <input
          type="file"
          accept="text/csv"
          onChange={(event) => {
            handleCsvFileUpload(event.target.files);
          }}
        />
      </div>
    );
  };

  const getPreviewTableHeader = () => {
    return (
      <tr>
        {csvJson[0].map((item: React.Key | null | undefined, index: number) => {
          return (
            <td key={item}>
              <select
                onChange={(event) => {
                  if (event.target.value === "postalCode") {
                    setPostalCodeIndex(index);
                  } else if (event.target.value === "addition") {
                    setAdditionIndex(index);
                  } else if (event.target.value === "housenumber") {
                    setHousenumberIndex(index);
                  } else if (event.target.value === "street") {
                    setStreetIndex(index);
                  }
                }}
              >
                {postalCodeIndex !== index &&
                housenumberIndex !== index &&
                streetIndex !== index &&
                additionIndex !== index ? (
                  <option selected>-</option>
                ) : (
                  <option>-</option>
                )}

                <option value="postalCode">Postcode</option>
                <option value="housenumber">Huisnummer</option>
                <option value="addition">Toevoeging</option>
                <option value="street">Straatnaam</option>
              </select>
            </td>
          );
        })}
      </tr>
    );
  };

  const getPreviewTableBody = () => {
    if (csvJson) {
      return csvJson.map((row, index) => {
        return (
          <tr key={`content${index}`}>
            {row.map((cell, cellIndex) => {
              return <td key={`cell${index}-${cellIndex}`}>{cell}</td>;
            })}
          </tr>
        );
      });
    }
  };

  const getPreviewTable = () => {
    return (
      <div>
        <div className={"previewTableHeader"}>
          <CheckBox
            ariaText={"Eerste rij toevoegen"}
            checked={useFirstRow}
            onChangeSetStateFunction={setUseFirstRow}
            text={"Eerste rij ook invoeren"}
          />

          <button
            className="button"
            onClick={() => {
              setIsLoading(true);
              importAddreses();
            }}
          >
            {useFirstRow ? csvJson.length : csvJson.length - 1} adressen
            importeren
          </button>
        </div>
        {errorMessage && (
          <div className={"message message--warning"} role={"alert"}>
            {errorMessage}
          </div>
        )}
        <table>
          <thead>{getPreviewTableHeader()}</thead>
          <tbody>{getPreviewTableBody()}</tbody>
        </table>
      </div>
    );
  };

  const validateAndFormatAddressData = () => {
    if (
      additionIndex === null ||
      housenumberIndex === null ||
      postalCodeIndex === null
    ) {
      setErrorMessage(
        "Selecteer welke kolom welke adres waarde (postcode, huisnummer of toevoeging) bevat"
      );
      return;
    }
    const addresses = [];
    for (
      let addressIndex = useFirstRow ? 0 : 1;
      addressIndex < (useFirstRow ? csvJson.length : csvJson.length - 1);
      addressIndex++
    ) {
      const postalCode: string = csvJson[addressIndex][postalCodeIndex];
      const housenumber: string = csvJson[addressIndex][housenumberIndex];
      const addition: string = csvJson[addressIndex][additionIndex];
      const street: string =
        streetIndex !== null ? csvJson[addressIndex][streetIndex] : null;
      if (postalCode.length !== 6) {
        setErrorMessage(
          `Alle postcodes moeten exact 6 karakters bevatten: 1234AB. Fout: "${postalCode}" Regel: ${
            addressIndex + 1
          }`
        );
        return;
      } else if (housenumber.length === 0) {
        setErrorMessage(
          `Voor alle addressen is een huisnummer verplicht. Regel: ${
            addressIndex + 1
          }`
        );
        return;
      }
      addresses.push({
        postalCode: postalCode,
        addition: addition,
        housenumber: housenumber,
        street: street,
      });
    }

    return addresses;
  };

  const importAddreses = async () => {
    const addresses = validateAndFormatAddressData();
    if (addresses) {
      if (!state.session.token || !playground) {
        setIsLoading(false);
        return;
      }

      try {
        await addBulkAddressToPlayground(
          playground,
          addresses,
          state.session.token
        );

        navigate("/playgrounds/" + playground.id + "/addresses");
      } catch (e) {
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };

  const getPageContent = () => {
    if (isLoading) {
      return <img src={"/assets/images/loader.svg"} />;
    } else if (!hasFile) {
      return getFileInput();
    } else if (csvJson.length > 0) {
      return getPreviewTable();
    }
  };
  return (
    <div>
      <NavBar playground={playground} />
      <div className={"block"}>
        <div className={"block__title"}>
          <h1>Adressen CSV import</h1>
        </div>
        <div className={"block__content"}>{getPageContent()}</div>
      </div>
    </div>
  );
};
