import { revokeSession } from "../../helpers/SessionHelper";
import { PhaseType } from "../../models/PhaseType";
import { PlaygroundType } from "../../models/PlaygroundType";
import { PlannedPhaseType } from "../../models/PlannedPhaseType";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getPhases = (sessionToken: string): Promise<Array<PhaseType>> => {
  return fetch(BASE_URL + "/phases", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "X-ACCESS-TOKEN": sessionToken,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        revokeSession();
        return;
      }
      return response.json();
    })
    .then((response) => response);
};

export const addPlannedPhase = (
  plannedPhase: PlannedPhaseType,
  playground: PlaygroundType,
  sessionToken: string
): Promise<PlannedPhaseType> => {
  return fetch(
    BASE_URL + "/playgrounds/" + playground.id + "/addPlannedPhase",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "X-ACCESS-TOKEN": sessionToken,
      },
      body: JSON.stringify({
        status: plannedPhase.phase,
        startDate: Math.round(
          new Date(plannedPhase.startDate).getTime() / 1000
        ),
        endDate: plannedPhase.endDate
          ? Math.round(new Date(plannedPhase.endDate).getTime() / 1000)
          : null,
      }),
    }
  )
    .then((response) => response.json())
    .then((response) => response)
    .catch((e) => {
      console.error(e);
    });
};
