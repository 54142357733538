import { PlaygroundType } from "../../models/PlaygroundType";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const sendMailing = (
  playground: PlaygroundType,
  sessionToken: string,
  emailTitle: string,
  emailBody: string
): Promise<boolean> => {
  return fetch(BASE_URL + "/playgrounds/" + playground.id + "/mailing", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "X-ACCESS-TOKEN": sessionToken,
    },
    body: JSON.stringify({ title: emailTitle, body: emailBody }),
  })
    .then((response) => response.json())
    .then((response) => response.isSent)
    .catch((e) => {
      console.error(e);
      return false;
    });
};
