import React from "react";
import { PlaygroundClientType } from "../../models/PlaygroundClientType";
import { ShoppinglistType } from "../../models/ShoppinglistType";
import { ConceptType } from "../../models/ConceptType";

export type ResultConceptsProps = {
  playground: PlaygroundClientType;
  shoppinglists: Array<ShoppinglistType>;
  onlyFinishedBoxes: boolean;
};

export type ConceptResultsType = {
  concept: ConceptType;
  count: number;
};

export const ResultConcepts = (props: ResultConceptsProps) => {
  const getResults = () => {
    const conceptResults: Array<ConceptResultsType> = [];

    props.playground.concepts.forEach((concept) => {
      conceptResults.push({ concept: concept, count: 0 });
    });

    props.shoppinglists.forEach((shoppingList) => {
      if (
        !props.onlyFinishedBoxes ||
        (props.onlyFinishedBoxes && shoppingList.isFinalized)
      ) {
        conceptResults.forEach((conceptResult) => {
          if (conceptResult.concept.id === shoppingList.votedConcept) {
            conceptResult.count++;
          }
        });
      }
    });

    return conceptResults.map((conceptResult) => {
      return (
        <div key={conceptResult.concept.id} className={"results__row"}>
          <div className={"results__count"}>{conceptResult.count}x</div>
          <div className={"results__title"}>{conceptResult.concept.title}</div>
        </div>
      );
    });
  };

  return <div className={"results"}>{getResults()}</div>;
};
