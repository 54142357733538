import { SpeeltuinchefState } from "../state/InitialState";
import { UserState } from "../state/UserState";
import { FamiliyMemberType } from "../../models/FamiliyMemberType";
import { ActivityType } from "../../models/ActivityType";
import { MoodboardType } from "../../models/MoodboardType";
import { PhaseType } from "../../models/PhaseType";
import { IngredientType } from "../../models/IngredientType";
import { PlaygroundType } from "../../models/PlaygroundType";

export type Action =
  | { type: "SET_SESSION_TOKEN"; payload: string }
  | { type: "SET_USER"; payload: UserState }
  | { type: "SET_FAMILIES"; payload: Array<FamiliyMemberType> }
  | { type: "SET_ACTIVITIES"; payload: Array<ActivityType> }
  | { type: "SET_MOODBOARDS"; payload: Array<MoodboardType> }
  | { type: "SET_PHASES"; payload: Array<PhaseType> }
  | { type: "SET_INGREDIENTS"; payload: Array<IngredientType> }
  | { type: "SET_PLAYGROUNDS"; payload: Array<PlaygroundType> };

export const reducer = (state: SpeeltuinchefState, action: Action) => {
  switch (action.type) {
    case "SET_SESSION_TOKEN":
      return {
        ...state,
        session: { ...state.session, token: action.payload },
      };
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };
    case "SET_FAMILIES":
      return {
        ...state,
        families: action.payload,
      };
    case "SET_MOODBOARDS":
      return {
        ...state,
        moodboards: action.payload,
      };
    case "SET_PHASES":
      return {
        ...state,
        phases: action.payload,
      };
    case "SET_INGREDIENTS":
      return {
        ...state,
        ingredients: action.payload,
      };
    case "SET_PLAYGROUNDS":
      return {
        ...state,
        playgrounds: action.payload,
      };
    case "SET_ACTIVITIES":
      return {
        ...state,
        activities: action.payload,
      };
    default:
      return state;
  }
};
