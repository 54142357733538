import React, { useContext, useState } from "react";
import { addUser } from "../../api/user/UserCalls";
import { SpeeltuinchefContext } from "../../store/Store";

export const AddUser = () => {
  const { state } = useContext(SpeeltuinchefContext);
  const [email, setEmail] = useState("");
  const [password, setPasword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [warningText, setWarningText] = useState<string | null>(null);
  const [successText, setSuccessText] = useState<null | string>(null);

  const saveNewUser = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    if (!state.session.token) {
      setIsLoading(false);
      return;
    }

    if (password.length < 7) {
      setWarningText("Wachtwoord moet minimaal 8 tekens bevatten");
      setIsLoading(false);
      return;
    }

    if (password !== repeatPassword) {
      setWarningText("Wachtwoorden niet gelijk");
      setIsLoading(false);
      return;
    }

    if (email.length < 3) {
      setWarningText("E-mail onjuist");
      setIsLoading(false);
      return;
    }

    await addUser(state.session.token, { email: email, password: password });
    setSuccessText("Beheerder toegevoegd");
    setWarningText(null);
    setEmail("");
    setPasword("");
    setRepeatPassword("");
    setIsLoading(false);
  };

  const warning = warningText ? (
    <div className={"message message--warning"} role={"alert"}>
      {warningText}
    </div>
  ) : null;

  const success = successText ? (
    <div className={"message message--success"} role={"alert"}>
      {successText}
    </div>
  ) : null;

  return (
    <div className={"block"}>
      <h1>Beheerder toevoegen</h1>
      <form onSubmit={saveNewUser}>
        {warning}
        {success}
        <p>
          <input
            type={"email"}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder={"E-mail"}
          />
        </p>
        <p>
          <input
            type={"password"}
            value={password}
            onChange={(e) => {
              setPasword(e.target.value);
            }}
            placeholder={"Wachtwoord"}
          />
        </p>
        <p>
          <input
            type={"password"}
            value={repeatPassword}
            onChange={(e) => {
              setRepeatPassword(e.target.value);
            }}
            placeholder={"Herhaal wachtwoord"}
          />
        </p>
        <button className={"button"}>
          {isLoading ? <img src={"/assets/images/loader.svg"} /> : "Toevoegen"}
        </button>
      </form>
    </div>
  );
};
