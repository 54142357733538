import { revokeSession } from "../../helpers/SessionHelper";
import { MoodboardType } from "../../models/MoodboardType";
import { PlaygroundClientType } from "../../models/PlaygroundClientType";
import { PlaygroundType } from "../../models/PlaygroundType";
import { MoodboardsUpdateRequest } from "../../models/request/MoodboardsUpdateRequest";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getMoodboards = (
  sessionToken: string
): Promise<Array<MoodboardType>> => {
  return fetch(BASE_URL + "/moodboards", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "X-ACCESS-TOKEN": sessionToken,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        revokeSession();
        return;
      }
      return response.json();
    })
    .then((response) => response);
};

export const setMoodboards = (
  sessionToken: string,
  playground: PlaygroundClientType | PlaygroundType,
  moodboardUpdate: MoodboardsUpdateRequest
): Promise<[]> => {
  return fetch(BASE_URL + "/playgrounds/" + playground.id + "/setMoodboards", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "X-ACCESS-TOKEN": sessionToken,
    },
    body: JSON.stringify(moodboardUpdate),
  })
    .then((response) => {
      if (response.status !== 200) {
        revokeSession();
        return;
      }
      return response.json();
    })
    .then((response) => response);
};
