import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SpeeltuinchefContext } from "../store/Store";
import { getPlaygroundById } from "../helpers/PlaygroundHelper";
import { NavBar } from "../components/organisms/NavBar";
import { addAddressToPlayground } from "../api/playgrounds/PlaygroundCalls";

export const PlaygroundNewAddressPage = () => {
  const { playgroundId } = useParams<{ playgroundId: string }>();
  const context = useContext(SpeeltuinchefContext);
  const { state } = context;
  const navigate = useNavigate();
  const playground = getPlaygroundById(parseInt(playgroundId ?? ""), context);

  const [postalCode, setPostalCode] = useState("");
  const [housenumber, setHousenumber] = useState("");
  const [addition, setAddition] = useState("");
  const [street, setStreet] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const addAddress = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    if (!state.session.token || !playground) {
      setIsLoading(false);
      return;
    }

    try {
      const newAddress = {
        postalCode: postalCode,
        housenumber: housenumber,
        addition: addition + "",
        street: street === "" ? null : street,
      };
      await addAddressToPlayground(playground, newAddress, state.session.token);

      navigate("/playgrounds/" + playground.id + "/addresses");
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <NavBar playground={playground} />
      <div className={"block"}>
        <div className={"block__title"}>
          <h1>Adressen</h1>
        </div>
        <div className={"block__content"}>
          <form onSubmit={addAddress}>
            <p>
              <input
                type={"text"}
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                placeholder={"Postcode"}
              />
            </p>
            <p>
              <input
                type={"text"}
                value={housenumber}
                onChange={(e) => setHousenumber(e.target.value)}
                placeholder={"Huisnummer"}
              />
            </p>
            <p>
              <input
                type={"text"}
                value={addition}
                onChange={(e) => setAddition(e.target.value)}
                placeholder={"Toevoeging (optioneel)"}
              />
            </p>
            <p>
              <input
                type={"text"}
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                placeholder={"Straatnaam (optioneel)"}
              />
            </p>
            <p>
              <button className={"button"}>
                {isLoading ? (
                  <img src={"/assets/images/loader.svg"} />
                ) : (
                  "Toevoegen"
                )}
              </button>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};
