import React from "react";
import { PlaygroundClientType } from "../../models/PlaygroundClientType";
import { ShoppinglistType } from "../../models/ShoppinglistType";
import { FamiliyMemberType } from "../../models/FamiliyMemberType";

export type ResultFamilyProps = {
  playground: PlaygroundClientType;
  shoppinglists: Array<ShoppinglistType>;
  onlyFinishedBoxes: boolean;
  families: Array<FamiliyMemberType> | null;
};

export type FamilyResultsType = {
  familyMember: FamiliyMemberType;
  count: number;
};

export const ResultFamily = (props: ResultFamilyProps) => {
  const sortFunction = (
    result1: FamilyResultsType,
    result2: FamilyResultsType
  ) => {
    if (result1.count > result2.count) {
      return -1;
    } else {
      return 1;
    }
  };

  const getResults = () => {
    if (!props.families) {
      return <div />;
    }

    const familyResults: Array<FamilyResultsType> = [];
    props.families.forEach((familyMember) => {
      familyResults.push({ familyMember: familyMember, count: 0 });
    });

    props.shoppinglists.forEach((shoppingList) => {
      if (
        !props.onlyFinishedBoxes ||
        (props.onlyFinishedBoxes && shoppingList.isFinalized)
      ) {
        shoppingList.family.forEach((family) => {
          familyResults.forEach((familyResult) => {
            if (familyResult.familyMember.id === family.id) {
              familyResult.count += family.count;
            }
          });
        });
      }
    });

    return familyResults.sort(sortFunction).map((familyResult) => {
      return (
        <div key={familyResult.familyMember.id} className={"results__row"}>
          <div className={"results__count"}>{familyResult.count}x</div>
          <div className={"results__title"}>
            {familyResult.familyMember.title}
          </div>
        </div>
      );
    });
  };

  return <div className={"results"}>{getResults()}</div>;
};
