import React from "react";
import { PlaygroundClientType } from "../../models/PlaygroundClientType";
import { ShoppinglistType } from "../../models/ShoppinglistType";
import { IngredientType } from "../../models/IngredientType";

export type ResultIngredientsProps = {
  playground: PlaygroundClientType;
  shoppinglists: Array<ShoppinglistType>;
  onlyFinishedBoxes: boolean;
};

export type IngredientResultsType = {
  ingredient: IngredientType;
  count: number;
  favoriteCount: number;
};

export const ResultIngredients = (props: ResultIngredientsProps) => {
  const sortFunction = (
    result1: IngredientResultsType,
    result2: IngredientResultsType
  ) => {
    if (result1.count > result2.count) {
      return -1;
    } else {
      return 1;
    }
  };
  const getResults = () => {
    const ingredientResults: Array<IngredientResultsType> = [];

    props.playground.ingredients.forEach((ingredient) => {
      ingredientResults.push({
        ingredient: ingredient,
        count: 0,
        favoriteCount: 0,
      });
    });

    props.shoppinglists.forEach((shoppingList) => {
      if (
        !props.onlyFinishedBoxes ||
        (props.onlyFinishedBoxes && shoppingList.isFinalized)
      ) {
        shoppingList.ingredients.forEach((ingredientId) => {
          ingredientResults.forEach((ingredientResult) => {
            if (ingredientResult.ingredient.id === ingredientId) {
              ingredientResult.count++;
            }
            if (
              ingredientResult.ingredient.id === shoppingList.favoriteIngredient
            ) {
              ingredientResult.favoriteCount++;
            }
          });
        });
      }
    });

    return ingredientResults.sort(sortFunction).map((ingredientResult) => {
      return (
        <div key={ingredientResult.ingredient.id} className={"results__row"}>
          <div className={"results__count"}>{ingredientResult.count}x</div>
          <div className={"results__title"}>
            {ingredientResult.ingredient.title}
          </div>
          <div className={"results__title"}>
            {ingredientResult.favoriteCount}{" "}
            <img src={"/assets/images/star-active.svg"} alt={"Ster"} />
          </div>
        </div>
      );
    });
  };

  return <div className={"results"}>{getResults()}</div>;
};
