// @ts-nocheck
import React from "react";
import { ShoppinglistType } from "../../models/ShoppinglistType";
import GoogleMapReact from "google-map-react";
import { PlaygroundClientType } from "../../models/PlaygroundClientType";
import { AddressType } from "../../models/AddressType";

export type ResultAddressesProps = {
  playground: PlaygroundClientType;
  shoppinglists: Array<ShoppinglistType>;
  onlyFinishedBoxes: boolean;
};

export type MarkerListProps = {
  lat: number | undefined;
  lng: number | undefined;
  address: AddressType;
};

export const ResultAddresses = (props: ResultAddressesProps) => {
  const MarkerList = ({ address }: MarkerListProps) => (
    <div>
      <img src={"/assets/images/logo_speeltuinchef-small.png"} />
      {address.postalCode}
    </div>
  );

  const getMarkers = () => {
    return props.shoppinglists.map((shoppinglist, index) => {
      if (props.onlyFinishedBoxes && !shoppinglist.isFinalized) {
        return;
      }
      return (
        <MarkerList
          key={"marker-shopping" + index}
          lat={shoppinglist.address.location?.lat}
          lng={shoppinglist.address.location?.lon}
          address={shoppinglist.address}
        />
      );
    });
  };

  const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY
    ? process.env.REACT_APP_GOOGLE_MAPS_KEY
    : "";

  const mapCenter = {
    lat: props.playground.location ? props.playground.location.lat : 0,
    lng: props.playground.location ? props.playground.location.lon : 0,
  };

  const getCountsOverview = () => {
    const postalcodesCounts: { [key: string]: number } = {};
    const shoppinglists = props.shoppinglists.filter(
      (shoppinglist) =>
        !props.onlyFinishedBoxes ||
        (props.onlyFinishedBoxes && shoppinglist.isFinalized)
    );

    shoppinglists.forEach((shoppinglist) => {
      const key: string = shoppinglist.address.postalCode.toUpperCase();
      if (key in postalcodesCounts) {
        postalcodesCounts[key]++;
      } else {
        postalcodesCounts[key] = 1;
      }
    });

    return Object.keys(postalcodesCounts).map((postalcode) => {
      return (
        <tr key={postalcode}>
          <td>{postalcode}:</td> <td>{postalcodesCounts[postalcode]}x</td>
        </tr>
      );
    });
  };

  return (
    <div>
      {/*Important! Always set the container height explicitly*/}
      <div style={{ height: 500, width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: googleMapsKey }}
          defaultCenter={mapCenter}
          defaultZoom={13}
        >
          {getMarkers()}
        </GoogleMapReact>
        <table style={{ marginTop: 10, marginBottom: 20 }}>
          {getCountsOverview()}
        </table>
      </div>
    </div>
  );
};
