import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SpeeltuinchefContext } from "../store/Store";
import {
  getConceptById,
  getPlaygroundById,
  getPlaygroundDetailsById,
} from "../helpers/PlaygroundHelper";
import { NavBar } from "../components/organisms/NavBar";
import { EditPlaygroundConcept } from "../components/organisms/EditPlaygroundConcept";
import { PlaygroundClientType } from "../models/PlaygroundClientType";
import { Loading } from "../components/molecules/Loading";

export const PlaygroundEditConceptPage = () => {
  const { playgroundId } = useParams<{ playgroundId: string }>();
  const { conceptId } = useParams<{ conceptId: string }>();
  const context = useContext(SpeeltuinchefContext);
  const playground = getPlaygroundById(parseInt(playgroundId ?? ""), context);

  const [
    apiPlayground,
    setApiPlayground,
  ] = useState<PlaygroundClientType | null>(null);

  const fetchPlayground = async () => {
    const apiPlayground = await getPlaygroundDetailsById(
      parseInt(playgroundId ?? ""),
      context
    );
    setApiPlayground(apiPlayground);
  };

  useEffect(() => {
    if (apiPlayground === null) {
      fetchPlayground();
    }
  });

  const concept = apiPlayground
    ? getConceptById(apiPlayground, parseInt(conceptId ?? ""))
    : null;

  return (
    <div>
      <NavBar playground={playground} />
      <div className={"block"}>
        <div className={"block__title"}>
          <h1>Bewerk concept</h1>
        </div>
        <div className={"block__content"}>
          {apiPlayground === null ? (
            <Loading />
          ) : (
            <EditPlaygroundConcept
              playground={apiPlayground}
              concept={concept}
            />
          )}
        </div>
      </div>
    </div>
  );
};
