import React, { createContext, useReducer } from "react";
import { initialState, SpeeltuinchefState } from "./state/InitialState";

import { reducer, Action } from "./reducer/reducer";

type ProviderProps = {
  children: React.ReactNode;
};

export type ContextType = {
  state: SpeeltuinchefState;
  dispatch: (action: Action) => void;
};

const SpeeltuinchefContext = createContext<ContextType>({
  state: initialState,
  dispatch: () => {},
});
const { Provider } = SpeeltuinchefContext;

const SpeeltuinProvider = (props: ProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <Provider value={{ state, dispatch }}>{props.children}</Provider>;
};

export { SpeeltuinchefContext, SpeeltuinProvider };
