import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { SpeeltuinchefContext } from "../store/Store";
import {
  getPlaygroundById,
  getPlaygroundDetailsById,
} from "../helpers/PlaygroundHelper";
import { PlaygroundClientType } from "../models/PlaygroundClientType";
import { NavBar } from "../components/organisms/NavBar";
import { Loading } from "../components/molecules/Loading";
import { CheckBox } from "../components/atoms/Checkbox";
import {
  removeAddressToPlayground,
  updatePlayground,
} from "../api/playgrounds/PlaygroundCalls";
import { AddressType } from "../models/AddressType";

export const PlaygroundEditAddressesPage = () => {
  const { playgroundId } = useParams<{ playgroundId: string }>();
  const context = useContext(SpeeltuinchefContext);
  const { state } = context;
  const playground = getPlaygroundById(parseInt(playgroundId ?? ""), context);

  const [isRestrictedByAddress, setIsRestrictedByAddress] = useState(false);

  const [
    apiPlayground,
    setApiPlayground,
  ] = useState<PlaygroundClientType | null>(null);

  const fetchPlayground = async () => {
    const apiPlayground = await getPlaygroundDetailsById(
      parseInt(playgroundId ?? ""),
      context
    );
    setApiPlayground(apiPlayground);

    if (apiPlayground) {
      setIsRestrictedByAddress(apiPlayground.isRestrictedByAddress);
    }
  };

  useEffect(() => {
    if (apiPlayground === null) {
      fetchPlayground();
    }
  });

  const changeIsRestrictedByAddress = (isRestriced: boolean): void => {
    setIsRestrictedByAddress(isRestriced);
    saveRestrictedByAddress(isRestriced);
  };

  const saveRestrictedByAddress = async (isRestriced: boolean) => {
    if (!state.session.token || !apiPlayground) {
      return;
    }
    try {
      apiPlayground.isRestrictedByAddress = isRestriced;
      await updatePlayground(apiPlayground, state.session.token);
    } catch (e) {
      console.error(e);
    }
  };

  const removeAddress = async (address: AddressType) => {
    if (address.id && playground && state.session.token) {
      await removeAddressToPlayground(
        playground,
        address.id,
        state.session.token
      );
      fetchPlayground();
    }
  };

  const getAddressTable = (addresses: Array<AddressType>): React.ReactNode => {
    return (
      <div className={"table"}>
        <div className={"table__row"}>
          <div className={"table__cell table__header"}>Postcode</div>
          <div className={"table__cell table__header"}>Huisnummer</div>
          <div className={"table__cell table__header"}>Toevoeging</div>
          <div className={"table__cell table__header"}>Straatnaam</div>
          <div className={"table__cell table__header"} />
        </div>
        {addresses.map((address, index) => {
          return (
            <div key={index} className={"table__row"}>
              <div className={"table__cell"}>{address.postalCode}</div>
              <div className={"table__cell"}>{address.housenumber}</div>
              <div className={"table__cell"}>{address.addition}</div>
              <div className={"table__cell"}>{address.street}</div>
              <div className={"table__cell table__action"}>
                <img
                  src={"/assets/images/menu/trash.svg"}
                  alt={"Prullenbak"}
                  onClick={() => {
                    removeAddress(address);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <NavBar playground={playground} />
      <div className={"block"}>
        <div className={"block__title"}>
          <h1>Adressen</h1>
        </div>
        <div className={"block__content"}>
          {apiPlayground === null ? (
            <Loading />
          ) : (
            <div>
              <div className={"block__right"}>
                <Link
                  to={"/playgrounds/" + apiPlayground.id + "/addresses/new"}
                >
                  <button className={"button button--margin-right"}>
                    Voeg adres toe
                  </button>
                </Link>
                <Link
                  to={"/playgrounds/" + apiPlayground.id + "/addresses/csv"}
                >
                  <button className={"button"}>CSV import</button>
                </Link>
              </div>
              <div>
                <CheckBox
                  checked={isRestrictedByAddress}
                  ariaText={"Alleen toegankelijk voor onderstaande adressen"}
                  onChangeValueFunction={changeIsRestrictedByAddress}
                  text={"Alleen toegankelijk voor onderstaande adressen"}
                />
              </div>
              {getAddressTable(apiPlayground.addresses)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
