import React from "react";
import { PlaygroundClientType } from "../../models/PlaygroundClientType";
import { ShoppinglistType } from "../../models/ShoppinglistType";
import { MoodboardType } from "../../models/MoodboardType";

export type ResultMoodboardsProps = {
  playground: PlaygroundClientType;
  shoppinglists: Array<ShoppinglistType>;
  onlyFinishedBoxes: boolean;
};

export type MoodboardResultsType = {
  moodboard: MoodboardType;
  count: number;
};

export const ResultMoodboards = (props: ResultMoodboardsProps) => {
  const sortFunction = (
    result1: MoodboardResultsType,
    result2: MoodboardResultsType
  ) => {
    if (result1.count > result2.count) {
      return -1;
    } else {
      return 1;
    }
  };
  const getResults = () => {
    const moodboardResults: Array<MoodboardResultsType> = [];

    props.playground.moodboards.forEach((moodboard) => {
      moodboardResults.push({ moodboard: moodboard, count: 0 });
    });

    props.shoppinglists.forEach((shoppingList) => {
      if (
        !props.onlyFinishedBoxes ||
        (props.onlyFinishedBoxes && shoppingList.isFinalized)
      ) {
        shoppingList.moodboards.forEach((moodboardId) => {
          moodboardResults.forEach((moodboardResult) => {
            if (moodboardResult.moodboard.id === moodboardId) {
              moodboardResult.count++;
            }
          });
        });
      }
    });

    return moodboardResults.sort(sortFunction).map((moodboardResult) => {
      return (
        <div key={moodboardResult.moodboard.id} className={"results__row"}>
          <div className={"results__count"}>{moodboardResult.count}x</div>
          <div className={"results__title"}>
            {moodboardResult.moodboard.title}
          </div>
        </div>
      );
    });
  };

  return <div className={"results"}>{getResults()}</div>;
};
