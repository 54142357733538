import React, { FormEvent, useContext, useState } from "react";
import { NavBar } from "../components/organisms/NavBar";
import { useNavigate, useParams } from "react-router-dom";
import { SpeeltuinchefContext } from "../store/Store";
import { getPlaygroundById } from "../helpers/PlaygroundHelper";
import { sendMailing } from "../api/mailing/MailingCalls";

export const PlaygroundMailingPage = () => {
  const { playgroundId } = useParams<{ playgroundId: string }>();
  const context = useContext(SpeeltuinchefContext);
  const { state } = context;
  const playground = getPlaygroundById(parseInt(playgroundId ?? ""), context);

  const [emailTitle, setEmailTitle] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState<null | string>(null);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);

  const handleMailing = async (event: FormEvent) => {
    event.preventDefault();

    setIsLoading(true);

    if (!state.session.token || !playground) {
      setIsLoading(false);
      return;
    }

    const success = await sendMailing(
      playground,
      state.session.token,
      emailTitle,
      emailBody
    );

    if (success) {
      setSuccessMessage("E-mail is verstuurd!");
      setIsLoading(false);
      setEmailTitle("");
      setEmailBody("");
      setErrorMessage(null);
    } else {
      setSuccessMessage(null);
      setIsLoading(false);
      setErrorMessage("E-mail versturen mislukt!");
    }
  };
  return (
    <div>
      <NavBar playground={playground} />
      <div className={"block"}>
        <div className={"block__title"}>
          <h1>Mailing versturen</h1>
        </div>
        <div className={"block__content"}>
          {successMessage && (
            <div className={"message message--success"} role={"alert"}>
              {successMessage}
            </div>
          )}
          {errorMessage && (
            <div className={"message message--warning"} role={"alert"}>
              {errorMessage}
            </div>
          )}
          <p>
            Verstuur een e-mail naar alle deelnemers aan speeltuin{" "}
            <strong>{playground?.name}</strong>. Je ontvangt zelf ook een kopie
            van de e-mail.
          </p>
          <form onSubmit={handleMailing}>
            <p>
              <input
                type={"text"}
                value={emailTitle}
                onChange={(e) => setEmailTitle(e.target.value)}
                placeholder={"Titel"}
              />
            </p>
            <p>
              <textarea
                placeholder={"Uw bericht"}
                onChange={(e) => setEmailBody(e.target.value)}
                value={emailBody}
              />
            </p>
            <button className={"button"} disabled={isLoading}>
              {isLoading ? (
                <img src={"/assets/images/loader.svg"} />
              ) : (
                "Versturen"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
