import { revokeSession } from "../../helpers/SessionHelper";
import { IngredientType } from "../../models/IngredientType";
import { PlaygroundClientType } from "../../models/PlaygroundClientType";
import { PlaygroundType } from "../../models/PlaygroundType";
import { IngredientsUpdateRequest } from "../../models/request/IngredientsUpdateRequest";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getIngredients = (
  sessionToken: string
): Promise<Array<IngredientType>> => {
  return fetch(BASE_URL + "/ingredients", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "X-ACCESS-TOKEN": sessionToken,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        revokeSession();
        return;
      }
      return response.json();
    })
    .then((response) => response);
};

export const setIngredients = (
  sessionToken: string,
  playground: PlaygroundClientType | PlaygroundType,
  ingredientsUpdate: IngredientsUpdateRequest
): Promise<[]> => {
  return fetch(BASE_URL + "/playgrounds/" + playground.id + "/setIngredients", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "X-ACCESS-TOKEN": sessionToken,
    },
    body: JSON.stringify(ingredientsUpdate),
  })
    .then((response) => {
      if (response.status !== 200) {
        revokeSession();
        return;
      }
      return response.json();
    })
    .then((response) => response);
};
