import { PlaygroundType } from "../models/PlaygroundType";
import { PhaseType } from "../models/PhaseType";
import { PlannedPhaseType } from "../models/PlannedPhaseType";

export const getPlaygroundPlannedPhaseByPhase = (
  playground: PlaygroundType,
  phase: PhaseType
): PlannedPhaseType | null => {
  let correctPlannedPhase = null;

  playground.plannedPhases.forEach((plannedPhase) => {
    if (plannedPhase.phase === phase.status) {
      correctPlannedPhase = plannedPhase;
    }
  });

  return correctPlannedPhase;
};

export const getPreviousPhaseStatus = (currentPhase: PhaseType): string => {
  if (currentPhase.status === "CONCEPT") {
    return "";
  } else if (currentPhase.status === "PREFERENCES") {
    return "CONCEPT";
  } else if (currentPhase.status === "AWAIT_CONCEPTS") {
    return "PREFERENCES";
  } else if (currentPhase.status === "CONCEPTS_VOTING") {
    return "AWAIT_CONCEPTS";
  } else if (currentPhase.status === "AWAIT_BUILD") {
    return "CONCEPTS_VOTING";
  } else if (currentPhase.status === "FINISHED") {
    return "AWAIT_BUILD";
  }

  return "";
};

export const getStartdateOfPhase = (
  plannedPhases: Array<PlannedPhaseType>,
  phaseStatus: string
): Date => {
  let startdate = new Date();

  plannedPhases.forEach((plannedPhase) => {
    if (plannedPhase.phase === phaseStatus) {
      startdate = new Date(plannedPhase.startDate);
    }
  });

  return startdate;
};
