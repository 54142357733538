import React from "react";
import { PlaygroundClientType } from "../../models/PlaygroundClientType";
import { ShoppinglistType } from "../../models/ShoppinglistType";

export type ResultRemarksProps = {
  playground: PlaygroundClientType;
  shoppinglists: Array<ShoppinglistType>;
  onlyFinishedBoxes: boolean;
};

export const ResultRemarks = (props: ResultRemarksProps) => {
  const getResultsPreferences = () => {
    return props.shoppinglists.map((shoppingList, index) => {
      if (
        !props.onlyFinishedBoxes ||
        (props.onlyFinishedBoxes && shoppingList.isFinalized)
      ) {
        return (
          <div className={"results__answer"} key={index}>
            {shoppingList.remark}
          </div>
        );
      }
    });
  };

  const getConceptsRemarks = () => {
    return props.shoppinglists.map((shoppingList, index) => {
      if (
        !props.onlyFinishedBoxes ||
        (props.onlyFinishedBoxes && shoppingList.isFinalized)
      ) {
        return (
          <div className={"results__answer"} key={index}>
            {shoppingList.votedConceptRemark}
          </div>
        );
      }
    });
  };

  return (
    <div className={"results"}>
      <h3>Voorkeuren</h3>
      {getResultsPreferences()} <h3>Concepten</h3>
      {getConceptsRemarks()}
    </div>
  );
};
