import React from "react";
import { QuestionType } from "../../models/QuestionType";

export type QuestionListProps = {
  questions: Array<QuestionType>;
  deleteQuestion: (questionId: number) => void;
};

export const QuestionList = (props: QuestionListProps) => {
  const getQuestionTypeAsExplainableText = (questionType: string): string => {
    if (questionType === "text") {
      return "Vrij invoerveld";
    } else if (questionType === "checkbox") {
      return "Aanvink vakjes";
    } else if (questionType === "select") {
      return "Keuzelijst";
    }
    return "";
  };

  const getQuestions = () => {
    return props.questions.map((question) => {
      return (
        <div className={"questionList__question"} key={question.id}>
          <div className={"questionList__cell"}>
            <span className={"questionList__title"}>{question.question}</span>
            {question.options.join(", ")}
          </div>
          <div className={"questionList__cell"}>
            {getQuestionTypeAsExplainableText(question.type)}
          </div>
          <div className={"questionList__cell"}>
            <img
              src={"/assets/images/menu/trash.svg"}
              alt={"Prullenbak"}
              className={"questionList__delete"}
              onClick={() => {
                props.deleteQuestion(question.id);
              }}
            />
          </div>
        </div>
      );
    });
  };
  return (
    <div className={"questionList"}>
      <div className={"questionList__questions"}>{getQuestions()}</div>
    </div>
  );
};
