import React from "react";
import { Link, useLocation } from "react-router-dom";
import { PlaygroundType } from "../../models/PlaygroundType";
import { PlaygroundClientType } from "../../models/PlaygroundClientType";

export type NavBarProps = {
  playground?: PlaygroundType | PlaygroundClientType | null;
};

export const NavBar = ({ playground }: NavBarProps) => {
  const location = useLocation();
  const getItemsGeneric = (): React.ReactNode => {
    const items = [
      ["Speeltuinen", "playgrounds", "leaf.svg"],
      ["Stijlen", "moodboards", "picture.svg"],
      ["Activiteiten", "activities", "bicycle.svg"],
      ["Ingrediënten", "ingredients", "cart.svg"],
      ["Beheerder toevoegen", "users", "users.svg"],
    ];

    return items.map((item) => {
      const className =
        "navbar__item" +
        (location.pathname.includes(item[1]) ? " navbar__item-active" : "");
      return (
        <Link key={"nav-" + item[1]} to={"/" + item[1]} className={className}>
          <img
            src={"/assets/images/menu/" + item[2]}
            alt={"icoon " + item[0]}
          />
          <div className={"navbar__item__text"}>{item[0]}</div>
        </Link>
      );
    });
  };

  const getItemsDetail = (): React.ReactNode => {
    const items = [
      ["Algemeen", "general", "leaf.svg"],
      ["Planning", "phases", "calendar-full.svg"],
      ["Adressen", "addresses", "map-marker.svg"],
      ["Stijlen", "moodboards", "picture.svg"],
      ["Ingrediënten", "ingredients", "cart.svg"],
      ["Concepten", "concepts", "construction.svg"],
      ["Resultaten", "results", "chart-bars.svg"],
      ["Mailing", "mailing", "envelope.svg"],
    ];

    let spliceStartIndex = 4;
    if (playground?.hasActivity) {
      items.splice(spliceStartIndex, 0, [
        "Activiteiten",
        "activities",
        "bicycle.svg",
      ]);
      spliceStartIndex++;
    }
    if (playground?.hasQuestionnaire) {
      items.splice(spliceStartIndex, 0, [
        "Vragenlijst",
        "questionnaire",
        "question-circle.svg",
      ]);
    }

    const playgroundId = playground && playground.id ? playground.id : "";

    return items.map((item) => {
      const className =
        "navbar__item" +
        (location.pathname.includes(item[1]) ? " navbar__item-active" : "");
      return (
        <Link
          key={"nav-" + item[1]}
          to={"/playgrounds/" + playgroundId + "/" + item[1]}
          className={className}
        >
          <img
            src={"/assets/images/menu/" + item[2]}
            alt={"icoon " + item[0]}
          />
          <div className={"navbar__item__text"}>{item[0]}</div>
        </Link>
      );
    });
  };

  if (playground) {
    return (
      <nav className={"navbar"}>
        <div className={"navbar__scroll"}>
          <div className={"navbar__logo"}>
            <img
              src={"/assets/images/logo_speeltuinchef.png"}
              alt={"Logo speeltuinchef"}
            />
          </div>
          <div className={"navbar__title"}>
            <h2>Speeltuinchef</h2>
          </div>
          <div className={"navbar__items"}>
            <Link to={"/playgrounds"} className={"navbar__item"}>
              <img
                src={"/assets/images/menu/arrow-left-circle.svg"}
                alt={"pijl terug "}
              />
              <div className={"navbar__item__text"}>Alle speeltuinen</div>
            </Link>
          </div>
          <div className={"navbar__title"}>
            <h1>{playground.name}</h1>
          </div>
          <div className={"navbar__items"}>{getItemsDetail()}</div>
        </div>
      </nav>
    );
  }

  return (
    <nav className={"navbar"}>
      <div className={"navbar__logo"}>
        <img
          src={"/assets/images/logo_speeltuinchef.png"}
          alt={"Logo speeltuinchef"}
        />
      </div>
      <div className={"navbar__title"}>
        <h2>Speeltuinchef</h2>
      </div>
      <div className={"navbar__items"}>{getItemsGeneric()}</div>
    </nav>
  );
};
