import { ContextType } from "../store/Store";
import { getUser } from "../api/user/UserCalls";
import { getFamilies } from "../api/family/FamilyCalls";
import { getMoodboards } from "../api/moodboards/MoodboardCalls";
import { getActivities } from "../api/activities/ActivityCalls";
import { getIngredients } from "../api/ingredients/IngredientCalls";
import { getPhases } from "../api/phases/PhaseCalls";
import { getPlaygrounds } from "../api/playgrounds/PlaygroundCalls";

export const hasValidSession = (context: ContextType): boolean => {
  const { state } = context;
  let sessionToken = state.session.token;

  if (sessionToken === null) {
    sessionToken = getSessionFromStorage();
  }

  return sessionToken !== null && sessionToken !== "";
};

export const getSessionToken = (context: ContextType): string => {
  const { state } = context;
  let sessionToken = state.session.token;

  if (sessionToken === null) {
    sessionToken = getSessionFromStorage();
  }

  return sessionToken;
};

export const getSessionFromStorage = (): string => {
  let sessionToken = sessionStorage.getItem("speeltuinchef-session");
  if (sessionToken === null) {
    sessionToken = localStorage.getItem("speeltuinchef-session");
  }
  return sessionToken ? sessionToken : "";
};

export const revokeSession = (): void => {
  if (sessionStorage.getItem("speeltuinchef-session")) {
    sessionStorage.removeItem("speeltuinchef-session");
  }
  if (localStorage.getItem("speeltuinchef-session")) {
    localStorage.removeItem("speeltuinchef-session");
  }

  location.href = "/login";
};

export const checkAndLoadState = async (context: ContextType) => {
  const { state, dispatch } = context;

  if (state.session.token === null) {
    await dispatch({
      type: "SET_SESSION_TOKEN",
      payload: getSessionFromStorage(),
    });
  }

  const promises = [];

  if (state.user.email === null) {
    promises.push(userFromApiToState(context));
  }
  if (state.families === null) {
    promises.push(familiesFromApiToState(context));
  }
  if (state.activities === null) {
    promises.push(activitiesFromApiToState(context));
  }
  if (state.moodboards === null) {
    promises.push(moodboardsFromApiToState(context));
  }
  if (state.ingredients === null) {
    promises.push(ingredientsFromApiToState(context));
  }
  if (state.phases === null) {
    promises.push(phasesFromApiToState(context));
  }
  if (state.playgrounds === null) {
    promises.push(playgroundsFromApiToState(context));
  }

  await Promise.all(promises);
};

export const userFromApiToState = async (context: ContextType) => {
  const { state, dispatch } = context;
  const sessionToken = state.session.token
    ? state.session.token
    : getSessionFromStorage();
  try {
    const response = await getUser(sessionToken);
    dispatch({ type: "SET_USER", payload: response });
  } catch (e) {
    console.log(e);
  }
};

export const familiesFromApiToState = async (context: ContextType) => {
  const { state, dispatch } = context;
  const sessionToken = state.session.token
    ? state.session.token
    : getSessionFromStorage();
  try {
    const response = await getFamilies(sessionToken);
    dispatch({ type: "SET_FAMILIES", payload: response });
  } catch (e) {
    console.log(e);
  }
};

export const activitiesFromApiToState = async (context: ContextType) => {
  const { state, dispatch } = context;
  const sessionToken = state.session.token
    ? state.session.token
    : getSessionFromStorage();
  try {
    const response = await getActivities(sessionToken);
    dispatch({ type: "SET_ACTIVITIES", payload: response });
  } catch (e) {
    console.log(e);
  }
};

export const moodboardsFromApiToState = async (context: ContextType) => {
  const { state, dispatch } = context;
  const sessionToken = state.session.token
    ? state.session.token
    : getSessionFromStorage();
  try {
    const response = await getMoodboards(sessionToken);
    dispatch({ type: "SET_MOODBOARDS", payload: response });
  } catch (e) {
    console.log(e);
  }
};

export const ingredientsFromApiToState = async (context: ContextType) => {
  const { state, dispatch } = context;
  const sessionToken = state.session.token
    ? state.session.token
    : getSessionFromStorage();
  try {
    const response = await getIngredients(sessionToken);
    dispatch({ type: "SET_INGREDIENTS", payload: response });
  } catch (e) {
    console.log(e);
  }
};

export const phasesFromApiToState = async (context: ContextType) => {
  const { state, dispatch } = context;
  const sessionToken = state.session.token
    ? state.session.token
    : getSessionFromStorage();
  try {
    const response = await getPhases(sessionToken);
    dispatch({ type: "SET_PHASES", payload: response });
  } catch (e) {
    console.log(e);
  }
};

export const playgroundsFromApiToState = async (context: ContextType) => {
  const { state, dispatch } = context;
  const sessionToken = state.session.token
    ? state.session.token
    : getSessionFromStorage();
  try {
    const response = await getPlaygrounds(sessionToken);
    dispatch({ type: "SET_PLAYGROUNDS", payload: response });
  } catch (e) {
    console.log(e);
  }
};
