import React, { Dispatch, SetStateAction } from "react";

export type SubMenuProps = {
  active: string;
  setActive: Dispatch<SetStateAction<string>>;
};

export const SubMenu = (props: SubMenuProps) => {
  const menuItems = [
    { name: "Adressen", key: "addresses" },
    { name: "Gezin", key: "family" },
    { name: "Stijlen", key: "moodboards" },
    { name: "Activiteiten", key: "activities" },
    { name: "Ingrediënten", key: "ingredients" },
    { name: "Vragen", key: "questionnaire" },
    { name: "Opmerkingen", key: "remarks" },
    { name: "Concepten", key: "concepts" },
  ];

  const getMenuItems = () => {
    return menuItems.map((menuItem) => {
      const classnames =
        props.active === menuItem.key
          ? "submenu__item submenu__item-active"
          : "submenu__item";
      return (
        <div
          key={menuItem.key}
          className={classnames}
          onClick={() => {
            props.setActive(menuItem.key);
          }}
        >
          {menuItem.name}
        </div>
      );
    });
  };

  return <div className={"submenu"}>{getMenuItems()}</div>;
};
