import React, { useContext, useState } from "react";
import { PlaygroundType } from "../../models/PlaygroundType";
import { SpeeltuinchefContext } from "../../store/Store";
import { PhasePicker } from "../molecules/PhasePicker";
import { PhaseType } from "../../models/PhaseType";
import {
  getPreviousPhaseStatus,
  getStartdateOfPhase,
} from "../../helpers/PhaseHelper";
import { PlannedPhaseType } from "../../models/PlannedPhaseType";
import { addPlannedPhase } from "../../api/phases/PhaseCalls";
import { playgroundsFromApiToState } from "../../helpers/SessionHelper";

export type EditPlaygroundPhasesProps = {
  playground: PlaygroundType;
};

export const EditPlaygroundPhases = (props: EditPlaygroundPhasesProps) => {
  const context = useContext(SpeeltuinchefContext);
  const { state } = context;

  const [warningText, setWarningText] = useState<null | string>(null);
  const [successText, setSuccessText] = useState<null | string>(null);
  const [update, setUpdate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  if (state.phases === null) {
    return <div />;
  }

  const updatePhase = (phase: PhaseType, date: Date) => {
    const previousPhaseStatus = getPreviousPhaseStatus(phase);
    const startdatePreviousPhase = getStartdateOfPhase(
      props.playground.plannedPhases,
      previousPhaseStatus
    );

    if (startdatePreviousPhase.getTime() > date.getTime()) {
      setWarningText(
        'Startdatum van "' +
          phase.name +
          '" kan niet voor start datum van vorige fase liggen'
      );
      return;
    }

    setWarningText(null);

    const plannedPhases: Array<PlannedPhaseType> = [];
    let phaseIsInPlannedPhases = false;
    props.playground.plannedPhases.forEach((plannedPhase) => {
      if (plannedPhase.phase === previousPhaseStatus) {
        plannedPhase.endDate = date.toISOString();
      }
      if (plannedPhase.phase === phase.status) {
        plannedPhase.startDate = date.toISOString();
        phaseIsInPlannedPhases = true;
      }

      plannedPhases.push(plannedPhase);
    });

    if (!phaseIsInPlannedPhases) {
      plannedPhases.push({
        phase: phase.status,
        startDate: date.toISOString(),
        endDate: null,
      });
    }

    props.playground.plannedPhases = plannedPhases;
    setUpdate(update + 1);
  };

  const saveAndContinue = async () => {
    setIsLoading(true);
    if (state.session.token === null) {
      location.href = "/login";
      return;
    } else {
      const sessiontoken = state.session.token;
      try {
        const promises: Array<Promise<PlannedPhaseType>> = [];
        props.playground.plannedPhases.forEach((plannedPhase) => {
          promises.push(
            addPlannedPhase(plannedPhase, props.playground, sessiontoken)
          );
        });

        await Promise.all(promises);
        await playgroundsFromApiToState(context);

        setSuccessText("Planning is opgeslagen");
        setIsLoading(false);
      } catch (e) {
        console.error(e);
        setWarningText(
          "Er is iets onverwachts mis gegaan, probeer het nog eens"
        );
        setIsLoading(false);
      }
    }
  };

  const warning = warningText ? (
    <div className={"message message--warning"} role={"alert"}>
      {warningText}
    </div>
  ) : null;

  const success = successText ? (
    <div className={"message message--success"} role={"alert"}>
      {successText}
    </div>
  ) : null;

  return (
    <div className={"phases"}>
      {success}
      <div className={"phases__table"}>
        <div className={"phases__item"}>
          <div className={"phases__key"}>
            <h2>Fase</h2>
          </div>
          <div className={"phases__value"}>
            <h2>Startdatum</h2>
          </div>
        </div>
        {state.phases.map((phase) => {
          return (
            <PhasePicker
              key={phase.id}
              phase={phase}
              playground={props.playground}
              updatePhase={updatePhase}
            />
          );
        })}
      </div>
      {warning}
      <button className={"button"} onClick={saveAndContinue}>
        {isLoading ? <img src={"/assets/images/loader.svg"} /> : "Opslaan"}
      </button>
    </div>
  );
};
