import React, { KeyboardEvent } from "react";
import { Dispatch, SetStateAction } from "react";

export interface CheckBoxProps {
  text?: string;
  textElement?: React.ReactNode;
  ariaText: string;
  checked: boolean;
  onChangeSetStateFunction?: Dispatch<SetStateAction<boolean>>;
  onChangeQuestionnaireFunction?: (answer: string) => void;
  onChangeVoteFunction?: (id: number) => void;
  onChangeValueFunction?: (isChecked: boolean) => void;
  id?: number;
  useStar?: boolean;
  useHeart?: boolean;
}

export const CheckBox = (props: CheckBoxProps) => {
  const catchSpacebarPress = (e: KeyboardEvent) => {
    if (e.key === " ") {
      e.preventDefault();
      if (props.onChangeSetStateFunction) {
        props.onChangeSetStateFunction(!props.checked);
      }
      if (props.onChangeQuestionnaireFunction && props.text) {
        props.onChangeQuestionnaireFunction(props.text);
      }
      if (props.onChangeVoteFunction && props.id) {
        props.onChangeVoteFunction(props.id);
      }
      if (props.onChangeValueFunction) {
        props.onChangeValueFunction(!props.checked);
      }
    }
  };
  return (
    <div className={"checkbox"}>
      <label
        className={"checkbox__label"}
        role="checkbox"
        aria-checked={props.checked}
        tabIndex={0}
        aria-label={props.ariaText}
        onKeyPress={catchSpacebarPress}
      >
        <input
          type={"checkbox"}
          checked={props.checked}
          onChange={(e) => {
            if (props.onChangeSetStateFunction) {
              props.onChangeSetStateFunction(e.target.checked);
            }
            if (props.onChangeQuestionnaireFunction && props.text) {
              props.onChangeQuestionnaireFunction(props.text);
            }
            if (props.onChangeVoteFunction && props.id) {
              props.onChangeVoteFunction(props.id);
            }
            if (props.onChangeValueFunction) {
              props.onChangeValueFunction(e.target.checked);
            }
          }}
        />
        <img
          className={"checkbox-selected checkbox__image"}
          src={
            props.useStar
              ? "/assets/images/star-active.svg"
              : props.useHeart
              ? "/assets/images/heart-filled.svg"
              : "/assets/images/checkbox_checked.png"
          }
          alt={"Geselecteerd aanvinkvakje"}
        />
        <img
          className={"checkbox-empty checkbox__image"}
          src={
            props.useStar
              ? "/assets/images/star-passive.svg"
              : props.useHeart
              ? "/assets/images/heart-empty.svg"
              : "/assets/images/checkbox_empty.png"
          }
          alt={"Leeg aanvinkvakje"}
        />
        <span>{props.text}</span>
        {props.textElement}
      </label>
    </div>
  );
};
