import { PlaygroundType } from "../models/PlaygroundType";
import { PhaseType } from "../models/PhaseType";
import { ContextType } from "../store/Store";
import { PlaygroundClientType } from "../models/PlaygroundClientType";
import { getPlaygroundDetails } from "../api/playgrounds/PlaygroundCalls";
import { ConceptType } from "../models/ConceptType";

export const getPlaygroundDetailsById = async (
  playgroundId: number,
  context: ContextType
): Promise<PlaygroundClientType | null> => {
  const playground = getPlaygroundById(playgroundId, context);
  const { state } = context;
  const sessiontoken = state.session.token;

  if (!sessiontoken || !playground) {
    return null;
  }

  return await getPlaygroundDetails(sessiontoken, playground);
};

export const getPlaygroundById = (
  playgroundId: number,
  context: ContextType
): PlaygroundType | null => {
  const { state } = context;

  if (!state.playgrounds) {
    return null;
  }

  let returnPlayground = null;
  state.playgrounds.forEach((playground) => {
    if (playground.id === playgroundId) {
      returnPlayground = playground;
    }
  });

  return returnPlayground;
};

export const getConceptById = (
  playground: PlaygroundClientType,
  conceptId: number
): ConceptType | null => {
  let returnConcept = null;
  playground.concepts.forEach((concept) => {
    if (concept.id === conceptId) {
      returnConcept = concept;
    }
  });

  return returnConcept;
};

export const getPlaygroundCurrentPhaseName = (
  playground: PlaygroundType,
  phases: Array<PhaseType> | null
): string => {
  if (!phases) {
    return "";
  }

  const phaseKey = getPlaygroundCurrentPhase(playground);
  let phaseDescription = "";
  phases.forEach((phase) => {
    if (phase.status === phaseKey) {
      phaseDescription = phase.name;
    }
  });
  return phaseDescription;
};

export const getPlaygroundCurrentPhase = (
  playground: PlaygroundType
): string => {
  let phaseKey = "";
  const now = new Date();

  playground.plannedPhases.forEach((phase) => {
    const startDate = Date.parse(phase.startDate);
    const endDate = phase.endDate ? Date.parse(phase.endDate) : null;
    if (
      startDate < now.getTime() &&
      (endDate === null || endDate > now.getTime())
    ) {
      phaseKey = phase.phase;
    }
  });

  return phaseKey;
};

export const sortPlaygroundAlphabetical = (
  playground1: PlaygroundType,
  playground2: PlaygroundType
): number => {
  if (playground1.name > playground2.name) {
    return 1;
  }
  if (playground1.name < playground2.name) {
    return -1;
  }

  return 0;
};

export const getPlaygroundsCenterLocation = (
  playgrounds: Array<PlaygroundType>
): { lat: number; lng: number } => {
  let totalLat = 0;
  let totalLon = 0;
  let playgroundsWithLocationCount = 0;

  playgrounds.forEach((playground) => {
    if (
      playground.location &&
      playground.location.lat &&
      playground.location.lon
    ) {
      playgroundsWithLocationCount++;
      totalLat += playground.location.lat;
      totalLon += playground.location.lon;
    }
  });

  return {
    lat: totalLat / playgroundsWithLocationCount,
    lng: totalLon / playgroundsWithLocationCount,
  };
};
