import { revokeSession } from "../../helpers/SessionHelper";
import { QuestionNewRequest } from "../../models/request/QuestionNewRequest";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const deleteQuestionFromPlayground = (
  sessionToken: string,
  playgroundId: number,
  questionId: number
): Promise<[]> => {
  return fetch(
    BASE_URL + "/playgrounds/" + playgroundId + "/removeQuestion/" + questionId,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "X-ACCESS-TOKEN": sessionToken,
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        revokeSession();
        return;
      }
      return response.json();
    })
    .then((response) => response);
};

export const addQuestionToPlayground = (
  sessionToken: string,
  playgroundId: number,
  question: QuestionNewRequest
): Promise<[]> => {
  return fetch(BASE_URL + "/playgrounds/" + playgroundId + "/addQuestion", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "X-ACCESS-TOKEN": sessionToken,
    },
    body: JSON.stringify(question),
  })
    .then((response) => {
      if (response.status !== 200) {
        revokeSession();
        return;
      }
      return response.json();
    })
    .then((response) => response);
};
