import React, { useContext } from "react";
import { NavBar } from "../components/organisms/NavBar";
import { Image } from "../components/atoms/Image";
import { SpeeltuinchefContext } from "../store/Store";

export const IngredientsPage = () => {
  const { state } = useContext(SpeeltuinchefContext);

  const getIngredients = (): React.ReactNode => {
    if (!state.ingredients) {
      return <div />;
    }
    return state.ingredients.map((ingredient) => {
      return (
        <div key={ingredient.id} className={"moodboardList__moodboard"}>
          {ingredient.title}
          <div className={"moodboardList__image"}>
            <Image image={ingredient.image} />
          </div>
          <div className={"moodboardList__coins"}>
            {getCoins(ingredient.points)}
          </div>
        </div>
      );
    });
  };

  const getCoins = (numberOfCoints: number): React.ReactNode => {
    const coins = [];
    for (let coinCount = 0; coinCount < numberOfCoints; coinCount++) {
      coins.push(<img src={"/assets/images/coin.svg"} alt={"Muntje"} />);
    }

    return <div>{coins}</div>;
  };

  return (
    <div>
      <NavBar />
      <div className={"block"}>
        <h1>Ingrediënten</h1> {getIngredients()}
      </div>
    </div>
  );
};
