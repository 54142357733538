import { PlaygroundClientType } from "../../models/PlaygroundClientType";
import { PlaygroundType } from "../../models/PlaygroundType";

import { revokeSession } from "../../helpers/SessionHelper";

import { ConceptRequest } from "../../models/request/ConceptRequest";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const addConcept = (
  sessionToken: string,
  playground: PlaygroundClientType | PlaygroundType,
  concept: ConceptRequest
): Promise<[]> => {
  return fetch(BASE_URL + "/playgrounds/" + playground.id + "/addConcept", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "X-ACCESS-TOKEN": sessionToken,
    },
    body: JSON.stringify(concept),
  })
    .then((response) => {
      if (response.status !== 200) {
        revokeSession();
        return;
      }
      return response.json();
    })
    .then((response) => response);
};

export const updateConcept = (
  sessionToken: string,
  playground: PlaygroundClientType | PlaygroundType,
  concept: ConceptRequest,
  conceptId: number
): Promise<[]> => {
  return fetch(
    BASE_URL + "/playgrounds/" + playground.id + "/editConcept/" + conceptId,
    {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "X-ACCESS-TOKEN": sessionToken,
      },
      body: JSON.stringify(concept),
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        //revokeSession();
        return;
      }
      return response.json();
    })
    .then((response) => response);
};


export const removeConcept = (
    sessionToken: string,
    playground: PlaygroundClientType | PlaygroundType,
    conceptId: number
): Promise<[]> => {
    return fetch(
        BASE_URL + "/playgrounds/" + playground.id + "/removeConcept/" + conceptId,
        {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "X-ACCESS-TOKEN": sessionToken,
            },
        }
    )
        .then((response) => {
            if (response.status !== 200) {
                //revokeSession();
                return;
            }
            return response.json();
        })
        .then((response) => response);
};
