import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { SpeeltuinchefContext } from "../store/Store";
import { getPlaygroundById } from "../helpers/PlaygroundHelper";
import { NavBar } from "../components/organisms/NavBar";
import { EditPlaygroundForm } from "../components/organisms/EditPlaygroundForm";

export const PlaygroundsEditGeneralPage = () => {
  const { playgroundId } = useParams<{ playgroundId: string }>();
  const context = useContext(SpeeltuinchefContext);
  const playground = getPlaygroundById(parseInt(playgroundId ?? ""), context);

  if (playground === null) {
    return <div />;
  }

  return (
    <div>
      <NavBar playground={playground} />
      <div className={"block"}>
        <div className={"block__title"}>
          <h1>Algemeen</h1>
        </div>
        <div className={"block__content"}>
          <EditPlaygroundForm playground={playground} />
        </div>
      </div>
    </div>
  );
};
