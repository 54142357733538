import { revokeSession } from "../../helpers/SessionHelper";
import { FamiliyMemberType } from "../../models/FamiliyMemberType";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getFamilies = (
  sessionToken: string
): Promise<Array<FamiliyMemberType>> => {
  return fetch(BASE_URL + "/family", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "X-ACCESS-TOKEN": sessionToken,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        revokeSession();
        return;
      }
      return response.json();
    })
    .then((response) => response);
};
