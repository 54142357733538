import React from "react";
import { NavBar } from "../components/organisms/NavBar";
import { NewPlaygroundForm } from "../components/organisms/NewPlaygroundForm";

export const PlaygroundsNewPage = () => {
  return (
    <div>
      <NavBar />
      <div className={"block"}>
        <div className={"block__title"}>
          <h1>Nieuwe speeltuin</h1>
        </div>
        <div className={"block__content"}>
          <NewPlaygroundForm />
        </div>
      </div>
    </div>
  );
};
