import React from "react";
import { NavBar } from "../components/organisms/NavBar";
import { AddUser } from "../components/organisms/AddUser";

export const UsersPage = () => {
  return (
    <div>
      <NavBar />
      <AddUser />
    </div>
  );
};
