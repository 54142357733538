import React, { useContext, useState } from "react";
import { MapViewNewPlayground } from "./MapViewNewPlayground";
import { SpeeltuinchefContext } from "../../store/Store";
import { newPlayground } from "../../api/playgrounds/PlaygroundCalls";
import { playgroundsFromApiToState } from "../../helpers/SessionHelper";
import { useNavigate } from "react-router-dom";

export const NewPlaygroundForm = () => {
  const context = useContext(SpeeltuinchefContext);
  const { state } = context;
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);

  const [warningText, setWarningText] = useState("");

  const savePlayground = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    if (state.session.token === null) {
      location.href = "/login";
      return;
    }

    if (lat === 0 || lon === 0) {
      setWarningText(
        "Selecteer een locatie van de speeltuin door op de kaart te klikken!"
      );
      setIsLoading(false);
      return;
    }
    if (name === "") {
      setWarningText("Kies een naam voor de speelplek!");
      setIsLoading(false);
      return;
    }

    try {
      const response = await newPlayground(
        {
          name: name,
          description: description,
          location: { lat: lat, lon: lon },
        },
        state.session.token
      );

      await playgroundsFromApiToState(context);

      navigate("/playgrounds/" + response.id + "/general");
    } catch (e) {
      setWarningText(
        "Er heeft zich een onverwachte fout voorgedaan, probeer het nog eens."
      );
      setIsLoading(false);
    }
  };

  const setLocation = (lat: number, lon: number) => {
    setLat(lat);
    setLon(lon);
  };

  const warning =
    warningText === "" ? null : (
      <div className={"message message--warning"} role={"alert"}>
        {warningText}
      </div>
    );

  return (
    <div className={"newplaygroundform"}>
      <form onSubmit={savePlayground} className={"newplaygroundform__form"}>
        <input
          type={"text"}
          placeholder={"Naam speeltuin"}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <textarea
          placeholder={"(optionele) omschrijving speeltuin voor intern gebruik"}
          onChange={(e) => setDescription(e.target.value)}
          value={description}
        />

        {warning}

        <button className={"button"}>
          {isLoading ? <img src={"/assets/images/loader.svg"} /> : "Toevoegen"}
        </button>
      </form>

      <div className={"newplaygroundform__map"}>
        <MapViewNewPlayground
          playgrounds={state.playgrounds}
          setLocation={setLocation}
        />
        <p>
          Selecteer de locatie van de speeltuin door te klikken op de kaart.
          Deze locatie wordt alleen binnen dit beheer systeem gebruikt.
        </p>
      </div>
    </div>
  );
};
