import React from "react";
import { PlaygroundClientType } from "../../models/PlaygroundClientType";
import { ShoppinglistType } from "../../models/ShoppinglistType";
import { ActivityType } from "../../models/ActivityType";

export type ResultActivitiesProps = {
  playground: PlaygroundClientType;
  shoppinglists: Array<ShoppinglistType>;
  onlyFinishedBoxes: boolean;
};

export type ActivitiesResultsType = {
  activity: ActivityType;
  count: number;
};

export const ResultActivities = (props: ResultActivitiesProps) => {
  const sortFunction = (
    result1: ActivitiesResultsType,
    result2: ActivitiesResultsType
  ) => {
    if (result1.count > result2.count) {
      return -1;
    } else {
      return 1;
    }
  };
  const getResults = () => {
    const activitiesResults: Array<ActivitiesResultsType> = [];

    props.playground.activities.forEach((activity) => {
      activitiesResults.push({ activity: activity, count: 0 });
    });

    props.shoppinglists.forEach((shoppingList) => {
      if (
        !props.onlyFinishedBoxes ||
        (props.onlyFinishedBoxes && shoppingList.isFinalized)
      ) {
        shoppingList.activities.forEach((activityId) => {
          activitiesResults.forEach((activityResult) => {
            if (activityResult.activity.id === activityId) {
              activityResult.count++;
            }
          });
        });
      }
    });

    return activitiesResults.sort(sortFunction).map((activitiesResult) => {
      return (
        <div key={activitiesResult.activity.id} className={"results__row"}>
          <div className={"results__count"}>{activitiesResult.count}x</div>
          <div className={"results__title"}>
            {activitiesResult.activity.title}
          </div>
        </div>
      );
    });
  };

  return <div className={"results"}>{getResults()}</div>;
};
