import React, { useContext } from "react";
import { NavBar } from "../components/organisms/NavBar";
import { Image } from "../components/atoms/Image";
import { SpeeltuinchefContext } from "../store/Store";

export const MoodboardsPage = () => {
  const { state } = useContext(SpeeltuinchefContext);

  const getMoodboards = (): React.ReactNode => {
    if (!state.moodboards) {
      return <div />;
    }
    return state.moodboards.map((moodboard) => {
      return (
        <div key={moodboard.id} className={"moodboardList__moodboard"}>
          {moodboard.title}
          <div className={"moodboardList__image"}>
            <Image image={moodboard.image} />
          </div>
        </div>
      );
    });
  };
  return (
    <div>
      <NavBar />
      <div className={"block"}>
        <h1>Stijlen</h1> {getMoodboards()}
      </div>
    </div>
  );
};
