import React, { useState } from "react";
import { PhaseType } from "../../models/PhaseType";
import { PlaygroundType } from "../../models/PlaygroundType";
import { getPlaygroundCurrentPhase } from "../../helpers/PlaygroundHelper";
import { getPlaygroundPlannedPhaseByPhase } from "../../helpers/PhaseHelper";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export type PhasePickerProps = {
  phase: PhaseType;
  playground: PlaygroundType;
  updatePhase: (phase: PhaseType, date: Date) => void;
};

export const PhasePicker = (props: PhasePickerProps) => {
  const plannedPhase = getPlaygroundPlannedPhaseByPhase(
    props.playground,
    props.phase
  );

  const [startDate, setStartDate] = useState<Date>(
    plannedPhase
      ? new Date(plannedPhase ? Date.parse(plannedPhase.startDate) : "")
      : new Date()
  );

  const getPicker = () => {
    if (props.phase.status === "CONCEPT") {
      const displayDate = plannedPhase
        ? new Date(plannedPhase.startDate)
        : new Date();
      return <div>{displayDate.toLocaleString()}</div>;
    }

    return (
      <div className={"phases__picker"}>
        <DatePicker
          selected={startDate}
          showTimeSelect
          onChange={(date) => {
            if (!Array.isArray(date) && date !== null) {
              props.updatePhase(props.phase, date);
            }
            if (date) {
              setStartDate(date);
            }
          }}
          dateFormat="dd-MM-yyyy HH:mm"
        />
      </div>
    );
  };

  return (
    <div className={"phases__item"}>
      <div className={"phases__key"}>
        <span className={"phases__title"}>
          {" "}
          {props.phase.name}{" "}
          {getPlaygroundCurrentPhase(props.playground) ===
          props.phase.status ? (
            <div>
              <img src={"/assets/images/check.svg"} alt={"vinkje"} /> Huidige
              fase
            </div>
          ) : (
            ""
          )}
        </span>
        <br />
        {props.phase.description}
      </div>
      <div className={"phases__value"}>{getPicker()}</div>
    </div>
  );
};
