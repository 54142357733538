import React, { useContext, useState } from "react";
import { MapViewNewPlayground } from "./MapViewNewPlayground";
import { SpeeltuinchefContext } from "../../store/Store";
import { PlaygroundType } from "../../models/PlaygroundType";
import {
  removePlayground,
  updatePlayground,
} from "../../api/playgrounds/PlaygroundCalls";
import { playgroundsFromApiToState } from "../../helpers/SessionHelper";
import { CheckBox } from "../atoms/Checkbox";

export type EditPlaygroundFormProps = {
  playground: PlaygroundType;
};

export const EditPlaygroundForm = ({ playground }: EditPlaygroundFormProps) => {
  const context = useContext(SpeeltuinchefContext);
  const { state } = context;

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [name, setName] = useState(playground.name);
  const [description, setDescription] = useState(playground.description);
  const [externalPageUrl, setExternalPageUrl] = useState<string | null>(
    playground.externalPageUrl
  );
  const [lat, setLat] = useState(playground.location?.lat);
  const [lon, setLon] = useState(playground.location?.lon);

  const [hasQuiz, setHasQuiz] = useState(playground.hasQuiz);
  const [hasQuestionnaire, setHasQuestionnaire] = useState(
    playground.hasQuestionnaire
  );
  const [hasFamily, setHasFamily] = useState(playground.hasFamily);
  const [hasActivity, setHasActivity] = useState(playground.hasActivity);

  const [warningText, setWarningText] = useState("");
  const [successText, setSuccessText] = useState("");

  const savePlayground = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    if (state.session.token === null) {
      location.href = "/login";
      return;
    }

    if (lat === 0 || lon === 0) {
      setWarningText(
        "Selecteer een locatie van de speeltuin door op de kaart te klikken!"
      );
      setIsLoading(false);
      return;
    }
    if (name === "") {
      setWarningText("Kies een naam voor de speelplek!");
      setIsLoading(false);
      return;
    }

    playground.name = name;
    playground.description = description;
    playground.location = lat && lon ? { lat: lat, lon: lon } : null;
    playground.hasQuestionnaire = hasQuestionnaire;
    playground.hasQuiz = hasQuiz;
    playground.externalPageUrl = externalPageUrl;
    playground.hasActivity = hasActivity;
    playground.hasFamily = hasFamily;

    try {
      await updatePlayground(playground, state.session.token);
      await playgroundsFromApiToState(context);

      setSuccessText("Wijziging opgeslagen");
      setIsLoading(false);
    } catch (e) {
      setWarningText(
        "Er heeft zich een onverwachte fout voorgedaan, probeer het nog eens."
      );
      setIsLoading(false);
    }
  };

  const setLocation = (lat: number, lon: number) => {
    setLat(lat);
    setLon(lon);
  };

  const warning =
    warningText === "" ? null : (
      <div className={"message message--warning"} role={"alert"}>
        {warningText}
      </div>
    );

  const success =
    successText === "" ? null : (
      <div className={"message message--success"} role={"alert"}>
        {successText}
      </div>
    );

  return (
    <div className={"newplaygroundform"}>
      <div>
        <form onSubmit={savePlayground} className={"newplaygroundform__form"}>
          {success}
          <input
            type={"text"}
            placeholder={"Naam speeltuin"}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <textarea
            placeholder={
              "(optionele) omschrijving speeltuin voor intern gebruik"
            }
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />

          <input
            type={"text"}
            placeholder={"Volledig webadres projectpagina (https://...)"}
            value={externalPageUrl ? externalPageUrl : ""}
            onChange={(e) => setExternalPageUrl(e.target.value)}
          />

          <p>Welke onderdelen worden uitgevraagd voor deze speeltuin?</p>

          <CheckBox
            ariaText={"Gebruikt speeltuin de quiz"}
            checked={hasQuiz}
            onChangeSetStateFunction={setHasQuiz}
            text={"Quiz "}
          />

          <CheckBox
            ariaText={"Gebruikt speeltuin de vragenlijst"}
            checked={hasQuestionnaire}
            onChangeSetStateFunction={setHasQuestionnaire}
            text={"Vragenlijst"}
          />
          <CheckBox
            ariaText={"Gebruikt speeltuin de jullie gezin"}
            checked={hasFamily}
            onChangeSetStateFunction={setHasFamily}
            text={"Jullie gezin"}
          />
          <CheckBox
            ariaText={"Gebruikt speeltuin de activiteiten"}
            checked={hasActivity}
            onChangeSetStateFunction={setHasActivity}
            text={"Activiteiten"}
          />

          {warning}

          <button className={"button"}>
            {isLoading ? <img src={"/assets/images/loader.svg"} /> : "Opslaan"}
          </button>
        </form>
        <button
          className={"button button-error"}
          onClick={async () => {
            if (state.session.token) {
              setIsDeleting(true);
              await removePlayground(playground, state.session.token);
              location.href = "/playgrounds";
            }
          }}
        >
          {isDeleting ? (
            <img src={"/assets/images/loader.svg"} />
          ) : (
            "Verwijder speeltuin"
          )}
        </button>
      </div>

      <div className={"newplaygroundform__map"}>
        <MapViewNewPlayground
          playgrounds={state.playgrounds}
          setLocation={setLocation}
          startLocation={playground.location}
        />
        <p>
          Selecteer de locatie van de speeltuin door te klikken op de kaart.
          Deze locatie wordt alleen binnen dit beheer systeem gebruikt.
        </p>
      </div>
    </div>
  );
};
